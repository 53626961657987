import React from "react";
import { Link as RouterLink } from "react-router-dom";
import { Duration } from "luxon";
import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  Divider,
  Link,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Stack,
  SvgIcon,
  Typography,
} from "@mui/material";
import { PhonelinkLock } from "@mui/icons-material";
import { mdiCertificateOutline } from "@mdi/js";
import ErrorBoundary from "../../../lib/classes/ErrorBoundary";
import LogoAssembler from "./LogoAssembler.jsx";

export const handle_projection = (iso) => {
  let duration = Duration.fromISO(iso !== undefined ? iso : "PT0S").toMillis();
  let projection = duration > 0 ? new Date(Date.now() + duration) : new Date();
  return projection.toLocaleDateString();
};

function ProductCard(props) {
  const deferred = React.useDeferredValue(props.credit);
  const projection = handle_projection(deferred.time);

  return (
    <ErrorBoundary>
      <Box
        component="span"
        sx={{
          justifyContent: "space-between",
          alignContent: "center",
          mt: 2,
          display: "inline-block",
          mx: "2px",
          minWidth: "18vw",
          minHeight: "36vh",
        }}
      >
        <Card
          variant="outlined"
          sx={{
            minHeight: "36vh",
            padding: "10px",
            textAlign: "center",
            display: "inline-block",
          }}
        >
          <CardContent>
            <Stack justifyContent="center">
              <Typography variant="h5" sx={{ my: 1 }}>
                {deferred.description}
              </Typography>
              <ErrorBoundary>
                <LogoAssembler
                  name={deferred.base_product}
                  kind={deferred.kind}
                />
              </ErrorBoundary>
            </Stack>
            <List
              dense
              sx={{
                width: "100%",
                maxWidth: 360,
              }}
              disablePadding
            >
              <ListItem sx={{ justifyContent: "stretch" }}>
                <ListItemText>
                  <Stack
                    direction="row"
                    sx={{
                      mx: "1rem",
                      mb: 0.5,
                      justifyContent: "space-between",
                    }}
                  >
                    <Typography
                      sx={{
                        fontSize: "1.6rem",
                        fontFamily: "Big Shoulders Text",
                      }}
                    >
                      Balance
                    </Typography>
                    <Typography
                      sx={{
                        fontSize: "1.6rem",
                        fontFamily: "Big Shoulders Text",
                        mx: 1,
                      }}
                    >
                      {deferred.qty}
                    </Typography>
                  </Stack>
                </ListItemText>
                <ListItemIcon>
                  <SvgIcon sx={{ minHeight: "2.8rem", minWidth: "2.8rem" }}>
                    <path d={mdiCertificateOutline} />
                  </SvgIcon>
                </ListItemIcon>
              </ListItem>
              <Divider variant="middle" />
              <Typography variant="caption">
                Projected expiry if activated today
              </Typography>
              <ListItem>
                <ListItemText sx={{ textAlign: "center" }}>
                  {" "}
                  {projection}{" "}
                </ListItemText>
              </ListItem>
            </List>
          </CardContent>
          {deferred.use_as !== "renew" && (
            <CardActions sx={{ justifyContent: "end" }}>
              {deferred.qty >= 1 ? (<Link
                component={RouterLink}
                to={`/credits/use?prod=${deferred.kind}-${deferred.base_product.replace(" ", "")}-${deferred.time}`}
              >
                <Button startIcon={<PhonelinkLock />} variant="contained">
                  Use
                </Button>
              </Link>) : (
                <Typography>Contact distributor!</Typography>
              )}
            </CardActions>
          )}
        </Card>
      </Box>
    </ErrorBoundary>
  );
}

export default ProductCard;
