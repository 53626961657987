import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link as RouterLink } from "react-router-dom";
import { awsLogout } from "../../store/auth/signinSlice";
import useNotifier from "../../lib/useNotifier";
import {
  AppBar,
  Badge,
  Box,
  Button,
  IconButton,
  Link,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  MenuList,
  Paper,
  SvgIcon,
  Toolbar,
} from "@mui/material";
import { Logout, Menu as MenuIcon, Notifications as NotificationsIcon } from "@mui/icons-material";
import {
  mdiCartPlus,
  mdiMonitorCellphone,
  mdiShieldAccountVariant,
  mdiViewList,
} from "@mdi/js";
import "./LogoutIcon.css";
import { uiActions } from "../../store/UI/uiSlice";
import LastTenAlerts from "../../store/notifications/LastTenAlerts";
import { toggleNotifierMenu } from "../../store/notifications/actions";

const menuItems = [
  {
    id: 0,
    label: "Subscription & Licence List",
    description:
      "Download QR codes, update licence tags, view new & unclaimed licence codes",
    icon: mdiViewList,
    component: RouterLink,
    linkout: "/subscriptions/",
  },
  {
    id: 1,
    label: "Product & Credit Inventory",
    description: "Exchange credits for licence codes",
    icon: mdiCartPlus,
    component: RouterLink,
    linkout: "/credits/",
  },
  {
    id: 2,
    label: "Web Install Appliance",
    description: "Use the Install Appliance to flash supported devices",
    icon: mdiMonitorCellphone,
    href: "https://install.copperhead.co/",
  },
];

const adminItems = [
  {
    adminId: "0a",
    label: "Credits Admin",
    description: "Assign credits",
    icon: mdiShieldAccountVariant,
    component: RouterLink,
    linkout: "/credits/admin",
  },
];

const MobileNav = () => {
  const { show_credits_admin } = useSelector((store) => store.user);

  return (
    <Paper sx={{ gridArea: "sidebar", bgcolor: "background.paper" }}>
      <MenuList dense>
        {menuItems.map((value, index) => {
          return (
            <MenuItem>
              <Link
                key={index}
                component={value.component}
                to={value.linkout}
                href={value.href}
              >
                <ListItemButton key={index}>
                  <ListItemIcon key={index}>
                    <SvgIcon key={index}>
                      <path key={index} d={value.icon} />
                    </SvgIcon>
                  </ListItemIcon>
                  <ListItemText
                    key="label"
                    primary={value.label}
                    primaryTypographyProps={{ fontSize: "0.9rem" }}
                    secondary={value.description}
                    secondaryTypographyProps={{ fontSize: "0.69rem" }}
                  />
                </ListItemButton>
              </Link>
            </MenuItem>
          );
        })}
        {show_credits_admin &&
          adminItems.map((value, index) => {
            return (
              <MenuItem>
                <Link
                  key={index}
                  component={value.component}
                  to={value.linkout}
                >
                  <ListItemButton key={index}>
                    <ListItemIcon key={index}>
                      <SvgIcon key={index}>
                        <path key={index} d={value.icon} />
                      </SvgIcon>
                    </ListItemIcon>
                    <ListItemText
                      key="label"
                      primary={value.label}
                      primaryTypographyProps={{ fontSize: "0.9rem" }}
                      secondary={value.description}
                      secondaryTypographyProps={{ fontSize: "0.69rem" }}
                    />
                  </ListItemButton>
                </Link>
              </MenuItem>
            );
          })}
      </MenuList>
    </Paper>
  );
};

const MobileBar = () => {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [notifEl, setNotifEl] = React.useState(null);
  const { lastTen, showNotifierMenu } = useSelector((store) => store.notifier);
  const { showNav } = useSelector((store) => store.ui);
  useNotifier();
  const dispatch = useDispatch();

  const logoutHandler = (event) => {
    event.preventDefault();

    dispatch(awsLogout());
  };

  const notifierMenuToggler = (event) => {
    setNotifEl(event.currentTarget);
    dispatch(toggleNotifierMenu());
  };

  const toggleHandler = (event) => {
    setAnchorEl(event.currentTarget);
    dispatch(uiActions.toggleNavBar());
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleNotifierClose = () => {
    setNotifEl(null);
    dispatch(toggleNotifierMenu());
  };

  return (
    <React.Fragment>
      <Box sx={{ gridArea: "header" }}>
        <AppBar position="static">
          <Toolbar sx={{ bgcolor: "primary.main" }}>
            <IconButton
              onClick={toggleHandler}
              id="menu-button"
              aria-controls={showNav ? "navigation-menu" : undefined}
              aria-haspopup="true"
              aria-expanded={showNav ? "true" : undefined}
            >
              <MenuIcon />
            </IconButton>
            <Link
              variant="h5"
              component={RouterLink}
              to="/"
              sx={{
                mx: 1,
                color: "#fff",
                textShadow: "1px 1px 2px black",
                flexGrow: 1,
              }}
            >
              Copperhead Partner Portal
            </Link>
            <IconButton onClick={notifierMenuToggler}>
              <Badge badgeContent={lastTen.length} color="error">
                <NotificationsIcon className="topbar-icon" />
              </Badge>
            </IconButton>
            <Button onClick={logoutHandler} color="inherit">
              <Logout className="logout-icon" />
            </Button>
          </Toolbar>
        </AppBar>
      </Box>
      <Menu
        id="navigation-menu"
        anchorEl={anchorEl}
        open={showNav}
        onClick={toggleHandler}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "menu-button",
        }}
        PaperProps={{
          elevation: 0,
          sx: {
            overflow: "visible",
            filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
            mt: 1.5,
            "& .MuiAvatar-root": {
              width: 32,
              height: 32,
              ml: -0.5,
              mr: 1,
            },
            "&:before": {
              content: '""',
              display: "block",
              position: "absolute",
              top: 0,
              right: 14,
              width: 10,
              height: 10,
              bgcolor: "background.paper",
              transform: "translateY(-50%) rotate(45deg)",
              zIndex: 0,
            },
          },
        }}
        anchorOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
      >
        <MobileNav />
      </Menu>
      <Menu
        id="notifications-menu"
        anchorEl={notifEl}
        open={showNotifierMenu}
        onClose={handleNotifierClose}
        MenuListProps={{
          "aria-labelledby": "menu-button",
        }}
        PaperProps={{
          elevation: 0,
          sx: {
            overflow: "visible",
            filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
            mt: 1.5,
            "& .MuiAvatar-root": {
              width: 32,
              height: 32,
              ml: -0.5,
              mr: 1,
            },
            "&:before": {
              content: '""',
              display: "block",
              position: "absolute",
              top: 0,
              right: 14,
              width: 10,
              height: 10,
              bgcolor: "background.paper",
              transform: "translateY(-50%) rotate(45deg)",
              zIndex: 0,
            },
          },
        }}
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        sx={{ minWidth: 240 }}
      >
        <LastTenAlerts />
      </Menu>
    </React.Fragment>
  );
};

export default MobileBar;
