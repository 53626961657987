import React from "react";
import { Container, Dialog } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import { useDispatch, useSelector } from "react-redux";
import { useParams, useSearchParams } from "react-router-dom";
import { adminActions } from "../../store/admin/adminSlice";
import CustomToolbar from "../custom/CustomToolbar";
import AddCredits from "./AddCredits";
import AddCreditsButton from "./AddCreditsButton";
import RemoveCreditsButton from "./RemoveCreditsButton";
import RemoveCredits from "./RemoveCredits";

const PartnerCredits = () => {
  const [dialogKind, setKind] = React.useState(0);
  const [dialogBaseProd, setBaseProd] = React.useState("");
  const [dialogType, setDialogType] = React.useState("");
  const [dialogDesc, setDialogDesc] = React.useState("");
  const [maxRemove, setMaxRemove] = React.useState(0);
  const [pageSizeIs, setPageSize] = React.useState(20);
  const params = useParams();
  const { partnerId } = params;
  const [search] = useSearchParams();
  const pattern = new RegExp("(meta=)([0-9]{1,2})(-)((?:...*))", "g");
  const meta = pattern.exec(search);
  const metaIndex = meta[2];
  const metaStrArray = meta[4].split(",");
  const metaBusiness = metaStrArray[0].replaceAll("+", " ");
  const {
    showAddDialog,
    showRemoveDialog,
    adminLoading,
    adminData,
    adminProdData,
    // adminBaseProds
  } = useSelector((store) => store.admin);
  const dispatch = useDispatch();
  const handleAddOpen = (kind, baseProduct, type, description) => {
    if (kind > 0) {
      setKind(kind);
      setBaseProd(baseProduct);
      setDialogType(type);
      setDialogDesc(description);
    }

    dispatch(adminActions.handleAddDialog());
  };
  const handleRemoveOpen = (kind, qty) => {
    if (kind > 0) {
      setKind(kind);
    }
    if (qty > 0) {
      setMaxRemove(qty);
    }

    dispatch(adminActions.handleRemoveDialog());
  };

  const handleClose = () => {
    dispatch(adminActions.handleAddDialog());
  };

  const handleRemoveClose = () => {
    dispatch(adminActions.handleRemoveDialog());
  };

  React.useLayoutEffect(() => {
    const savedPartnerGridSize = localStorage.getItem(
      "adm-partner-grid-pagesize"
    );

    if (savedPartnerGridSize) {
      setPageSize(+savedPartnerGridSize);
    }

    return () => {};
  }, []);

  let columns = [];

  const rows = [];
  for (const n in adminData[metaIndex].kinds) {
    const product = {
      id: `${partnerId}-` + n,
      ...adminData[metaIndex].kinds[n],
    };
    rows.push(product);
  }

  if (rows.length === 0) {
    rows.push({
      id: 1,
      kind: 0,
      qty: 0,
      placeholder: "Click to assign product credits",
    });

    columns = [
      {
        field: "placeholder",
        headerName: "Assign credits?",
        flex: 1,
        minWidth: 256,
      },
      {
        field: "add",
        headerName: "Add",
        minWidth: 100,
        align: "center",
        sortable: false,
        renderCell: (params) => (
          <AddCreditsButton params={params} handleAddOpen={handleAddOpen} />
        ),
      },
    ];
  } else {
    columns = [
      {
        field: "kind",
        headerName: "Product ID #",
        flex: 0.5,
        minWidth: 74,
      },
      {
        field: "base_product",
        headerName: "Product",
        flex: 1,
        minWidth: 256,
        valueGetter: (params) => {
          for (const index in adminProdData) {
            const metadata = {
              ...adminProdData[index],
            };
            if (params.row.kind === metadata.kind) {
              return metadata.base_product;
            }
          }
        },
      },
      {
        field: "description",
        headerName: "Description",
        flex: 1,
        minWidth: 256,
        valueGetter: (params) => {
          for (const idx in adminProdData) {
            const descData = {
              ...adminProdData[idx],
            };
            if (params.row.kind === descData.kind) {
              return descData.description;
            }
          }
        },
      },
      {
        field: "qty",
        headerName: "Current quantity",
        flex: 1,
        minWidth: 80,
        maxWidth: 160,
        align: "right",
      },
      {
        field: "add",
        headerName: "Add",
        minWidth: 100,
        align: "center",
        sortable: false,
        renderCell: (params) => (
          <AddCreditsButton params={params} handleAddOpen={handleAddOpen} />
        ),
      },
      {
        field: "remove",
        headerName: "Remove",
        minWidth: 100,
        align: "center",
        sortable: false,
        renderCell: (params) => (
          <RemoveCreditsButton
            params={params}
            handleRemoveOpen={handleRemoveOpen}
          />
        ),
      },
    ];
  }

  return (
    <React.Fragment key="dialog-partner-details">
      <Dialog
        disableEnforceFocus
        disablePortal
        open={showAddDialog}
        onClose={handleClose}
        aria-labelledby="dialog-credits-add"
        aria-describedby="dialog-credits-add"
        sx={{
          justifySelf: "center",
          justifyContent: "center",
          alignSelf: "center",
          alignContent: "center",
          alignItems: "center",
          display: "flex",
        }}
      >
        <AddCredits
          partner_id={partnerId}
          business_name={metaBusiness}
          kind={dialogKind}
          base_product={dialogBaseProd}
          type={dialogType}
          desc={dialogDesc}
        />
      </Dialog>
      <Dialog
        disableEnforceFocus
        disablePortal
        open={showRemoveDialog}
        onClose={handleRemoveClose}
        aria-labelledby="dialog-credits-remove"
        aria-describedby="dialog-credits-remove"
        sx={{
          justifySelf: "center",
          justifyContent: "center",
          alignSelf: "center",
          alignContent: "center",
          alignItems: "center",
          display: "flex",
        }}
      >
        <RemoveCredits
          partner_id={partnerId}
          business_name={metaBusiness}
          kind={dialogKind}
          maxQty={maxRemove}
        />
      </Dialog>
      <Container sx={{ height: "81.5vh", width: "auto" }}>
        <DataGrid
          initialState={{
            sorting: {
              sortModel: [{ field: "qty", sort: "desc" }],
            },
          }}
          loading={adminLoading}
          components={{
            Toolbar: CustomToolbar,
          }}
          componentsProps={{
            toolbar: {
              header: `Expanded credit overview for partner #${partnerId}, ${metaBusiness}`,
              showRefresh: true,
              refreshAdmin: true,
            },
          }}
          rows={rows}
          columns={columns}
          rowBuffer={10}
          density="compact"
          pageSize={pageSizeIs}
          rowsPerPageOptions={[5, 10, 15, 20, 50, 100]}
          onPageSizeChange={(pageSize) => {
            localStorage.setItem("adm-partner-grid-pagesize", pageSize);
            setPageSize(pageSize);
          }}
        />
      </Container>
    </React.Fragment>
  );
};

export default PartnerCredits;
