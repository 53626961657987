import React from "react";
import { AddTask, CloseSharp } from "@mui/icons-material";
import {
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogTitle,
  Divider,
  FormControlLabel,
  FormGroup,
  MenuItem,
  TextField,
  Typography,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { adminActions, createPortalUser } from "../../store/admin/adminSlice";
import { enqueueSnackbar } from "../../store/notifications/actions";

const AddPortalUser = () => {
  const [value, setValue] = React.useState(0);
  const [allowExtend, setExtendAllowed] = React.useState(true);
  const [allowNew, setNewAllowed] = React.useState(true);
  const selectRef = React.useRef();
  const emailRef = React.useRef();
  const { showAddUserDialog, adminPartners } = useSelector(
    (store) => store.admin
  );
  const { partner_id, partner_name } = useSelector((store) => store.user);
  const dispatch = useDispatch();

  const handleChange = (event) => {
    setValue(event.target.value);
  };

  const handleAllowNew = (event) => {
    setNewAllowed(event.target.checked);
  };

  const handleAllowExtend = (event) => {
    setExtendAllowed(event.target.checked);
  };

  const handleUserSubmit = async (event) => {
    event.preventDefault();

    const userEmail = emailRef.current.value.trim();

    if (userEmail.length < 6) {
      dispatch(
        enqueueSnackbar({
          message: "An email address cannot be less than 6 characters.",
          options: {
            variant: "warning",
            autoHideDuration: 12000,
            resumeHideDuration: 800,
          },
        })
      );

      return;
    }

    if (!userEmail.includes("@")) {
      dispatch(
        enqueueSnackbar({
          message: "You must provide a valid email address, or the user will not receive an invitation.",
          options: {
            variant: "warning",
            autoHideDuration: 12000,
            resumeHideDuration: 800,
          },
        })
      );

      return;
    }

    const partnerTarget = selectRef.current.value;

    if (partnerTarget === 0) {
      dispatch(
        enqueueSnackbar({
          message: "You must select a partner business for this user.",
          options: {
            variant: "warning",
            autoHideDuration: 12000,
            resumeHideDuration: 800,
          },
        })
      );

      return;
    }
    const userMangle = `{ "partner_id": ${partnerTarget}, "username": "${userEmail}", "allow_new_activation": ${allowNew}, "allow_extend": ${allowExtend} }`;
    const submitObject = JSON.parse(userMangle);

    dispatch(createPortalUser(submitObject));
    dispatch(adminActions.handleAddUserDialog());
  };

  const handleClose = (event) => {
    event.preventDefault();
    dispatch(adminActions.handleAddUserDialog());
  };

  return (
    <Dialog
      disableEnforceFocus
      disablePortal
      open={showAddUserDialog}
      onClose={handleClose}
      aria-labelledby="dialog-portal-user-add"
      aria-describedby="dialog-portal-user-add"
      sx={{
        justifySelf: "center",
        justifyContent: "center",
        alignSelf: "center",
        alignContent: "center",
        alignItems: "center",
        display: "flex",
      }}
    >
      <DialogTitle sx={{ p: 1, mb: 1 }}>Add a New Portal User</DialogTitle>
      <FormGroup>
        <TextField
          id="outlined-select-business"
          select
          label="User business ID:"
          helperText="Add this user to a partner business."
          value={value}
          onChange={handleChange}
          inputRef={selectRef}
          sx={{ p: 1 }}
        >
          <MenuItem value={0}>
            Select a business to add this user to...
          </MenuItem>
          {adminPartners.map((partner) => (
            <MenuItem key={partner.id} value={partner.id}>
              {partner.id}, {partner.business_name}
            </MenuItem>
          ))}
          <Divider variant="middle" />
          <Typography variant="caption">This business:</Typography>
          <MenuItem value={partner_id}>
            {partner_id}, {partner_name}
          </MenuItem>
        </TextField>
        <TextField
          sx={{ p: 1 }}
          label="User email"
          type="email"
          inputRef={emailRef}
        ></TextField>
        <FormControlLabel
          sx={{ p: 1 }}
          control={<Checkbox defaultChecked />}
          label="Allow New-activation products"
          onChange={handleAllowNew}
        />
        <FormControlLabel
          sx={{ p: 1 }}
          control={<Checkbox defaultChecked />}
          label="Allow Renew products"
          onChange={handleAllowExtend}
        />
      </FormGroup>
      <DialogActions sx={{ justifyContent: "space-between" }}>
        <Button
          variant="contained"
          endIcon={<CloseSharp />}
          onClick={handleClose}
        >
          Cancel
        </Button>
        <Button
          variant="contained"
          startIcon={<AddTask />}
          onClick={handleUserSubmit}
        >
          Submit
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default AddPortalUser;
