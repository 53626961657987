import { Avatar } from "@mui/material";
import React from "react";
import { useTheme } from "@mui/material/styles";
import CopperheadLogo from "../../custom/CopperheadLogo.jsx";
import CryptoCloud from "../../custom/CryptoCloud.jsx";
import Kontact from "../../custom/Kontact.jsx";
import DarkOS from "../../custom/DarkOS.jsx";
import SilentSecretLogo from "../../../static/img/products/silent_secret.png";
import KingCow from "../../../static/img/products/kc_wallpaper.jpg";

function useStyler(theme) {
  let themed = "#e3e3e3";
  if (theme.palette.mode === "dark") {
    themed = "black";
  }

  return {
    p: "2px",
    textAlign: "center",
    bgcolor: themed,
    width: "auto",
    height: 92,
  };
};

function LogoAssembler(props) {
  const copperheadStyles = useStyler(useTheme());
  const kontactStyles = {
    p: "1px",
    textAlign: "center",
    width: 136,
    height: 136,
    my: "1vh",
    ml: 6,
  };
  const cryptoStyles = {
    p: "1px",
    textAlign: "center",
    width: 278,
    height: 53,
    my: "1vh",
  };
  const darkOSStyles = {
    p: "1px",
    textAlign: "center",
    width: 270,
    height: 92,
    my: "1vh",
  };
  const silentStyles = {
    p: "1px",
    textAlign: "center",
    width: 278,
    height: 92,
    my: "1vh",
  };
  const { name } = React.useDeferredValue(props);
  if (!name) {
    throw new Error(
      "You must provide a name prop for this component to render."
    );
  }

  switch (name) {
    case "DarkOS" || "Dark OS":
      return <DarkOS variant="rounded" alt={name} sx={darkOSStyles} />;
    case "Silent Secret" || "SilentSecret":
      return <Avatar variant="rounded" src={SilentSecretLogo} alt={name} sx={silentStyles} />;
    case "King Cow Finance" || "King Cow Finanaces" || "KingCowFinance":
      return <Avatar variant="rounded" src={KingCow} alt={name} sx={silentStyles} />;
    case "CopperheadOS" || "Wolflock":
      return <CopperheadLogo name={name} sx={copperheadStyles} />;
    case "CryptoLock":
      return (
        <CryptoCloud
          subprod={name}
          variant="rounded"
          sx={cryptoStyles}
        />
      );
    case "CryptoLite":
      return (
        <CryptoCloud
          subprod={name}
          variant="rounded"
          sx={cryptoStyles}
        />
      );
    case "CryptoWork":
      return (
        <CryptoCloud
          subprod={name}
          variant="rounded"
          sx={cryptoStyles}
        />
      );
    case "Kontact":
      return <Kontact alt={name} variant="rounded" sx={kontactStyles} />;
    case "QA Rebrand":
      return <CopperheadLogo name={name} sx={copperheadStyles} />;
    default:
      return (
        <Avatar sx={cryptoStyles} variant="rounded">
          {name}
        </Avatar>
      );
  }
}

export default LogoAssembler;
