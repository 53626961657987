import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link as RouterLink } from "react-router-dom";
import { awsLogout } from "../../store/auth/signinSlice";
import { uiActions } from "../../store/UI/uiSlice";
import useNotifier from "../../lib/useNotifier";
import {
  AppBar,
  Badge,
  Box,
  Button,
  IconButton,
  Link,
  Menu,
  Toolbar,
} from "@mui/material";
import {
  Logout,
  Menu as MenuIcon,
  Notifications as NotificationsIcon,
} from "@mui/icons-material";
import "./TopBarIcons.css";
import { toggleNotifierMenu } from "../../store/notifications/actions";
import LastTenAlerts from "../../store/notifications/LastTenAlerts";

const TopBar = () => {
  const { lastTen, showNotifierMenu } = useSelector((store) => store.notifier);
  const [notifEl, setNotifEl] = React.useState(null);
  useNotifier();
  const dispatch = useDispatch();

  const logoutHandler = (event) => {
    event.preventDefault();

    dispatch(awsLogout());
  };

  const toggleHandler = (event) => {
    event.preventDefault();
    dispatch(uiActions.toggleNavBar());
  };

  const notifierMenuToggler = (event) => {
    setNotifEl(event.currentTarget);
    dispatch(toggleNotifierMenu());
  };

  const handleClose = () => {
    setNotifEl(null);
    dispatch(toggleNotifierMenu());
  };

  return (
    <React.Fragment>
      <Box sx={{ gridArea: "header" }}>
        <AppBar position="static">
          <Toolbar sx={{ bgcolor: "primary.main" }}>
            <IconButton onClick={toggleHandler}>
              <MenuIcon />
            </IconButton>
            <Link
              variant="h5"
              component={RouterLink}
              to="/"
              sx={{
                mx: 1,
                color: "#fff",
                textShadow: "1px 1px 2px black",
                flexGrow: 1,
              }}
            >
              Copperhead Partner Portal
            </Link>
            <IconButton onClick={notifierMenuToggler}>
              <Badge badgeContent={lastTen.length} color="error">
                <NotificationsIcon className="topbar-icon" />
              </Badge>
            </IconButton>
            <Button onClick={logoutHandler} color="inherit">
              <Logout className="topbar-icon" />
            </Button>
          </Toolbar>
        </AppBar>
      </Box>
      <Menu
        id="notifications-menu"
        anchorEl={notifEl}
        open={showNotifierMenu}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "menu-button",
        }}
        PaperProps={{
          elevation: 0,
          sx: {
            overflow: "visible",
            filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
            mt: 1.5,
            "& .MuiAvatar-root": {
              width: 32,
              height: 32,
              ml: -0.5,
              mr: 1,
            },
            "&:before": {
              content: '""',
              display: "block",
              position: "absolute",
              top: 0,
              right: 14,
              width: 10,
              height: 10,
              bgcolor: "background.paper",
              transform: "translateY(-50%) rotate(45deg)",
              zIndex: 0,
            },
          },
        }}
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        sx={{ minWidth: 240 }}
      >
        <LastTenAlerts />
      </Menu>
    </React.Fragment>
  );
};

export default TopBar;
