import React from "react";
import { SvgIcon } from "@mui/material";
import { useTheme } from "@mui/material/styles";

function CopperheadLogo(props) {
  const theme = useTheme();
  return (
    <SvgIcon
      sx={props.sx}
      titleAccess="CopperheadSec Logo"
      viewBox="0 0 188.5 186.8"
      alt={props.name}
    >
      <path
        d="M86.6 125.2L63.9 85.3h-7.6l30.1 52.8m.1 0l31.1-52.8h-7.4l-23.6 39.9m53.1-53.3l-30.3 53.4-3.6-6.5 22.7-40.4H83.3l-3.9-6.5h60.3M56.2 59.5l30.4 53.4 3.6-6.5-22.8-40.3h45.2l3.9-6.5"
        fill={theme.palette.mode === "dark" ? "#231f20" : "#fff"}
      />
      <path
        d="M94.3 23.7c38.5 0 69.7 31.2 69.7 69.7s-31.2 69.7-69.7 69.7-69.7-31.2-69.7-69.7 31.2-69.7 69.7-69.7h0zM63.8 85.3h-7.6l30.1 52.8 31.1-52.8H110l-23.6 39.9-22.6-39.9zm75.8-13.4H79.3l3.9 6.5h45.2l-22.7 40.4 3.6 6.5 30.3-53.4zm-23.2-12.4H56.1l30.3 53.4 3.6-6.5L67.3 66h45.2l3.9-6.5z"
        fillRule="evenodd"
        fill={theme.palette.mode === "dark" ? "#fff" : "#231f20"}
      />
    </SvgIcon>
  );
}

export default CopperheadLogo;
