import React from "react";
import { Avatar, useMediaQuery } from "@mui/material";
import { useTheme } from "@mui/styles";
import { QrCode2 } from "@mui/icons-material";

const QrViewer = (props) => {
  const { qrError, qrLoading, url } = React.useDeferredValue(props);
  const [qrSizing, setQrSizing] = React.useState({
    p: "5px",
    mx: "5vw",
    mb: "5vh",
    minHeight: "24rem",
    minWidth: "24rem",
    backgroundColor: "white",
  });
  const theme = useTheme();
  const xsScreen = useMediaQuery(theme.breakpoints.up("xs"));
  const smScreen = useMediaQuery(theme.breakpoints.up("sm"));
  const mdScreen = useMediaQuery(theme.breakpoints.up("md"));
  const lgScreen = useMediaQuery(theme.breakpoints.up("lg"));
  const xlScreen = useMediaQuery(theme.breakpoints.up("xl"));

  React.useLayoutEffect(() => {
    if (xsScreen && !smScreen && !mdScreen && !lgScreen && !xlScreen) {
      setQrSizing({
        p: "5px",
        mx: "1vw",
        my: "5vh",
        minHeight: "18rem",
        minWidth: "18rem",
        backgroundColor: "white",
      });
    }

    if (smScreen && !mdScreen && !lgScreen && !xlScreen) {
      setQrSizing({
        p: "5px",
        mx: "2vw",
        my: "5vh",
        minHeight: "24rem",
        minWidth: "24rem",
        backgroundColor: "white",
      });
    }

    if (mdScreen && !smScreen && !xsScreen && !lgScreen && !xlScreen) {
      setQrSizing({
        p: "5px",
        mx: "3vw",
        my: "5vh",
        minHeight: "36rem",
        minWidth: "36rem",
        backgroundColor: "white",
      });
    }

    if ((lgScreen || xlScreen) && !xsScreen && !smScreen && !mdScreen) {
      setQrSizing({
        p: "5px",
        mx: "5vw",
        my: "5vh",
        minHeight: "42rem",
        minWidth: "42rem",
        backgroundColor: "white",
      });
    }
  }, [xsScreen, smScreen, mdScreen, lgScreen, xlScreen]);

  return (
    <React.Fragment>
      {!qrError || !qrLoading ? (
        <Avatar variant="square" src={url} alt="qr-code" sx={qrSizing}>
          <QrCode2 sx={qrSizing} />
        </Avatar>
      ) : (
        <Avatar
          variant="square"
          alt="QR Code could not be loaded."
          sx={qrSizing}
        >
          <QrCode2 sx={qrSizing} />
        </Avatar>
      )}
    </React.Fragment>
  );
};

export default QrViewer;
