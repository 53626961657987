import { Grid4x4Sharp, ViewCarouselSharp } from "@mui/icons-material";
import { Button, IconButton, Stack, Toolbar, Tooltip } from "@mui/material";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import ErrorBoundary from "../../lib/classes/ErrorBoundary";
import { creditActions } from "../../store/credits/creditsSlice";
import { uiActions } from "../../store/UI/uiSlice";

const DisplayToolbar = () => {
  const { creditsLayout } = useSelector((store) => store.ui);
  const { showRenewals } = useSelector((store) => store.credits);
  const [carousel, setCarousel] = React.useState(false);
  const [grid, setGrid] = React.useState(true);
  const dispatch = useDispatch();

  const handleToggle = () => {
    dispatch(creditActions.toggleRenewals());
  };

  const handleCarousel = (event) => {
    event.preventDefault();
    dispatch(uiActions.setLayout("carousel"));
  };

  const handleGrid = (event) => {
    event.preventDefault();
    dispatch(uiActions.setLayout("grid"));
  };

  React.useEffect(() => {
    if (creditsLayout === "carousel") {
      setCarousel(true);
      setGrid(false);
    }

    if (creditsLayout === "grid") {
      setCarousel(false);
      setGrid(true);
    }
  }, [creditsLayout]);

  return (
    <ErrorBoundary>
      <Toolbar sx={{ justifyContent: "space-between" }}>
        {showRenewals === true && (
          <Tooltip title="Hide renewal product cards">
            <Button onClick={handleToggle} size="small">
              Hide Renew Credits
            </Button>
          </Tooltip>
        )}
        {showRenewals === false && (
          <Tooltip title="Show renewal product cards">
            <Button onClick={handleToggle}>Show Renew Credits</Button>
          </Tooltip>
        )}
        <Stack direction="row" spacing={0} sx={{ justifyContent: "end" }}>
        <Tooltip title="Display grid layout">
            <span>
              <IconButton
                disabled={grid}
                size="small"
                onClick={handleGrid}
              >
                <Grid4x4Sharp />
              </IconButton>
            </span>
          </Tooltip>
          <Tooltip title="Display carousel layout">
            <span>
              <IconButton
                disabled={carousel}
                size="small"
                onClick={handleCarousel}
              >
                <ViewCarouselSharp />
              </IconButton>
            </span>
          </Tooltip>
        </Stack>
      </Toolbar>
    </ErrorBoundary>
  );
};

export default DisplayToolbar;
