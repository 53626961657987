import React from "react";
import { Link as RouterLink } from "react-router-dom";
import { useSelector } from "react-redux";
import {
  Box,
  Divider,
  Link,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Paper,
  SvgIcon,
  Typography,
} from "@mui/material";
import {
  mdiCartPlus,
  mdiHome,
  mdiMonitorCellphone,
  mdiShieldAccountVariant,
  mdiViewList,
} from "@mdi/js";

const menuItems = [
  {
    id: 0,
    label: "Home",
    description: "News and Support Information",
    icon: mdiHome,
    component: RouterLink,
    linkout: "/",
  },
  {
    id: 1,
    label: "Subscription & Licence List",
    description:
      "Download QR codes, update licence tags, view new & unclaimed licence codes",
    icon: mdiViewList,
    component: RouterLink,
    linkout: "/subscriptions/",
  },
  {
    id: 2,
    label: "Product & Credit Inventory",
    description: "Exchange credits for licence codes",
    icon: mdiCartPlus,
    component: RouterLink,
    linkout: "/credits/",
  },
  {
    id: 3,
    label: "Web Install Appliance",
    description: "Use the Install Appliance to flash supported devices",
    icon: mdiMonitorCellphone,
    href: "https://install.copperhead.co/",
  },
];

const adminItems = [
  {
    adminId: "0a",
    label: "Credits Admin",
    description: "Assign credits",
    icon: mdiShieldAccountVariant,
    component: RouterLink,
    linkout: "/credits/admin",
  },
];

const NavBar = () => {
  const { partner_name, show_credits_admin } = useSelector(
    (store) => store.user
  );

  return (
    <Paper sx={{ gridArea: "sidebar", bgcolor: "background.paper" }}>
      <Box>
        <List component="nav">
          <ListItem>
            <Typography>Welcome, {partner_name}.</Typography>
          </ListItem>
          <Divider />
          {menuItems.map((value) => {
            return (
              <Link
                key={value.id}
                component={value.component}
                to={value.linkout}
                href={value.href}
              >
                <ListItemButton key={value.id}>
                  <ListItemIcon key={value.id}>
                    <SvgIcon key={value.id}>
                      <path key={value.id} d={value.icon} />
                    </SvgIcon>
                  </ListItemIcon>
                  <ListItemText
                    key="label"
                    primary={value.label}
                    primaryTypographyProps={{ fontSize: "0.9rem" }}
                    secondary={value.description}
                    secondaryTypographyProps={{ fontSize: "0.69rem" }}
                  />
                </ListItemButton>
              </Link>
            );
          })}
          {show_credits_admin &&
            adminItems.map((value) => {
              return (
                <Link
                  key={value.adminId}
                  component={value.component}
                  to={value.linkout}
                >
                  <ListItemButton key={value.adminId}>
                    <ListItemIcon key={value.adminId}>
                      <SvgIcon key={value.adminId}>
                        <path key={value.adminId} d={value.icon} />
                      </SvgIcon>
                    </ListItemIcon>
                    <ListItemText
                      key="label"
                      primary={value.label}
                      primaryTypographyProps={{ fontSize: "0.9rem" }}
                      secondary={value.description}
                      secondaryTypographyProps={{ fontSize: "0.69rem" }}
                    />
                  </ListItemButton>
                </Link>
              );
            })}
        </List>
      </Box>
    </Paper>
  );
}

export default NavBar;
