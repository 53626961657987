import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Box, Dialog, Paper } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import ErrorBoundary from "../../lib/classes/ErrorBoundary";
import CustomToolbar from "../custom/CustomToolbar";
import { adminActions } from "../../store/admin/adminSlice";
import AddCreditsSelf from "./AddCreditsSelf";

const CreditAdminGrid = () => {
  const [pageSizeIs, setPageSize] = React.useState(20);
  const { showSelfServ, adminLoading, adminData, adminProdData } = useSelector(
    (store) => store.admin
  );
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const handleClose = () => {
    dispatch(adminActions.handleSelfServ());
  };

  React.useLayoutEffect(() => {
    const savedPageSize = localStorage.getItem("adm-main-grid-pagesize");

    if (savedPageSize) {
      setPageSize(+savedPageSize)
    }

    return () => {};
  },[])

  const columns = [
    {
      field: "id",
      headerName: "Partner #",
      flex: 0.3,
      minWidth: 80,
    },
    {
      field: "business_name",
      headerName: "Partner Company",
      flex: 1,
      minWidth: 256,
    },
    {
      field: "kinds",
      headerName: "Product(s)",
      flex: 1,
      minWidth: 256,
      sortable: false,
      type: "string",
      valueGetter: (params) => {
        const kindNames = [];
        for (const key in adminData) {
          const partner = {
            ...adminData[key],
          };
          if (params.row.id === partner.id) {
            for (const indx in partner.kinds) {
              const product = {
                ...partner.kinds[indx],
              };
              for (const seq in adminProdData) {
                const desc = {
                  ...adminProdData[seq],
                };
                if (desc.kind === product.kind) {
                  Object.assign(product, desc);
                }
              }
              kindNames.push(product.base_product);
            }
          }
        }
        if (kindNames.length > 0) {
          return kindNames.toString().replaceAll(",", ", ");
        } else if (kindNames.length === 0) {
          return `No product(s) found for partner id: "${params.row.id}"`;
        }
      },
    },
    {
      field: "activeCredits",
      headerName: "Total Active Credits",
      width: 136,
      align: "right",
    },
    {
      field: "subCount",
      headerName: "Total Subscriptions",
      width: 136,
      align: "right",
    },
  ];

  if (!adminData) {
    return (
      <React.Fragment>
        <h2>Nice! Our web dev broke the grid with bad array mapping.</h2>
        <h4>
          The error has been automatically reported, we apologize for any
          inconvenience.
        </h4>
      </React.Fragment>
    );
  }

  return (
    <React.Fragment>
      <Dialog
        disableEnforceFocus
        disablePortal
        open={showSelfServ}
        onClose={handleClose}
        aria-labelledby="dialog-self-service"
        aria-describedby="dialog-self-service"
        sx={{
          justifySelf: "center",
          justifyContent: "center",
          alignSelf: "center",
          alignContent: "center",
          alignItems: "center",
          display: "flex",
        }}
      >
        <AddCreditsSelf />
      </Dialog>
      <ErrorBoundary>
        <Box sx={{ height: "81.5vh", width: "auto", mx: 0.5 }}>
          <Paper
            sx={{
              display: "flex",
              flexDirection: "column",
              flexGrow: 1,
              height: "81.5vh",
              alignItems: "center"
            }}
          >
            <DataGrid
              sx={{
                minWidth: "62vw",
              }}
              loading={adminLoading}
              components={{
                Toolbar: CustomToolbar,
              }}
              componentsProps={{
                toolbar: {
                  header: "Partners and Credit Balances",
                  showRefresh: true,
                  refreshAdmin: true,
                  showSelfServ: true,
                },
              }}
              rows={adminData}
              columns={columns}
              rowBuffer={10}
              density="compact"
              pageSize={pageSizeIs}
              rowsPerPageOptions={[5, 10, 15, 20, 50, 75, 100]}
              onPageSizeChange={(pageSize) => {
                localStorage.setItem("adm-main-grid-pagesize", pageSize);
                setPageSize(pageSize);
              }}
              onRowClick={(params) => {
                const curIndex = adminData.findIndex(
                  (element) => element.id === params.row.id
                );
                navigate(
                  `${params.row.id}?meta=${curIndex}-${params.row.business_name}`
                );
              }}
            />
          </Paper>
        </Box>
      </ErrorBoundary>
    </React.Fragment>
  );
}

export default CreditAdminGrid;
