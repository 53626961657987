import React from "react";
import PropTypes from "prop-types";
import { Lock, LockOpenOutlined } from "@mui/icons-material";
import { Tooltip } from "@mui/material";

const EnrollStatus = (props) => {
  const {initial_claim_used, currently_claimed} = React.useDeferredValue(props);

  return (
    <React.Fragment>
      <Tooltip title="Displays licence enrollment status. Open Lock means unclaimed (not used). The closed green lock means claimed and enrolled with a device. The closed yellow lock means claimed and awaiting re-enroll.">
        <span>
          {(!initial_claim_used && !currently_claimed) && <LockOpenOutlined />}
          {(!initial_claim_used && currently_claimed) && <LockOpenOutlined />}
          {(initial_claim_used && !currently_claimed) && <Lock color="warning" />}
          {(initial_claim_used && currently_claimed) && <Lock color="success" />}
        </span>
      </Tooltip>
    </React.Fragment>
  );
};

EnrollStatus.propTypes = {
  initial_claim_used: PropTypes.bool,
  currently_claimed: PropTypes.bool,
};

export default EnrollStatus;
