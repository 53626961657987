import React from "react";
import { useSelector } from "react-redux";
import GetUserData from "./GetUserData";

const UserStatus = () => {
  const { partner_id } = useSelector((store) => store.user);

  if (partner_id !== "") {
    return <GetUserData />;
  } else {
    return <></>;
  }
};

export default UserStatus;
