import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { adminActions } from "../store/admin/adminSlice";

const GetAdmin = () => {
  const { updateAdmin } = useSelector((store) => store.admin);
  const dispatch = useDispatch();

  React.useEffect(() => {
    const adminInterval = setInterval(() => {
      dispatch(adminActions.setUpdateAdmin());
    }, 600000);

    return () => clearInterval(adminInterval);
  }, [dispatch, updateAdmin]);

  return <React.Fragment />;
};

export default GetAdmin;
