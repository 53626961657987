import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import expiryConverter from "./expiryConverter";
import { api_path } from "../../config/site";
import { getAccessToken } from "../auth/signinSlice";
import { enqueueSnackbar } from "../notifications/actions";
import { userActions } from "../user/userSlice";
import fromHeader from "./fromHeader";

const initialState = {
  subsListing: [],
  totalSubs: 0,
  subsLoading: true,
  loadingStateMachine: "idle",
  updateSubs: "no",
  subsError: {},
  subErrorBool: false,
};

export const extendSub = (input) => {
  return async (dispatch) => {
    try {
      const accessToken = getAccessToken();
      const response = await fetch(
        api_path + `/subscription/${+input.ssid}/extend`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${accessToken}`,
          },
          body: JSON.stringify({ kind: +input.kind }),
        }
      );
      if (!response.ok) {
        throw new Error(response.statusText);
      }
      const result = await response.json();
      if (result) {
        dispatch(
          enqueueSnackbar({
            message: `Your subscription for: ${input.identifier} was successfully renewed!`,
            options: {
              variant: "success",
              autoHideDuration: 12000,
              resumeHideDuration: 800,
            },
          })
        );

        dispatch(userActions.toggleExtendDialog());
      }

      return result;
    } catch (error) {
      console.log(error);
      dispatch(
        enqueueSnackbar({
          message: "The server returned an error status: " + error,
          options: {
            variant: "error",
            autoHideDuration: 12000,
            resumeHideDuration: 800,
          },
        })
      );
      dispatch(userActions.toggleExtendDialog());
      return error;
    }
  };
};

export const getSubscriptionData = createAsyncThunk(
  "subs/getSubListing",
  async (_, thunkAPI) => {
    try {
      const partner_id = localStorage.getItem("CopperheadPartner_id");
      const accessToken = getAccessToken();
      const subsRes = await fetch(
        api_path + `/partner/${+partner_id}/subscription/list`,
        {
          mode: "cors",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );
      if (!subsRes.ok) {
        throw new Error("Failed to retrieve subscription listing.");
      }
      const subData = await subsRes.json();
      const subArray = [];
      let totalSubs = 0;
      for (const key in subData) {
        const subObj = {
          ...subData[key],
        };
        const subProduct = fromHeader(subObj.licence_id)
        Object.assign(subObj, subProduct);
        if (subObj.expiry_date) {
          const subExpiry = expiryConverter(subObj.expiry_date);
          Object.assign(subObj, subExpiry);
        }
        subArray.push(subObj);
        totalSubs++;
      }
      return { subListing: subArray, subCount: totalSubs };
    } catch (error) {
      return thunkAPI.rejectWithValue(error.message);
    }
  }
);

const subscriptionsSlice = createSlice({
  name: "subs",
  initialState: initialState,
  reducers: {
    setUpdateSubs: {
      reducer: (state) => {
        if (state.updateSubs === "no") {
          state.updateSubs = "yes";
        } else {
          state.updateSubs = "no";
        }
      },
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getSubscriptionData.pending, (state) => {
      state.subsLoading = true;
      state.loadingStateMachine = "pending";
    });
    builder.addCase(getSubscriptionData.fulfilled, (state, action) => {
      state.subsListing = action.payload.subListing;
      state.totalSubs = action.payload.subCount;
      state.subsLoading = false;
      state.loadingStateMachine = "idle";
      state.updateSubs = "no";
    });
    builder.addCase(getSubscriptionData.rejected, (state, action) => {
      state.subsLoading = true;
      state.loadingStateMachine = "idle";
      state.subErrorBool = true;
      state.updateSubs = "no";
      state.subsError = action.payload;
    });
  },
});

export const subscriptionActions = subscriptionsSlice.actions;

export default subscriptionsSlice.reducer;
