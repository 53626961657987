import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { api_path } from "../../config/site";
import { getAccessToken } from "../auth/signinSlice";

const initialState = {
  userdataLoading: "idle",
  user_name: "",
  partner_name: "",
  partner_id: "",
  show_credits_admin: false,
  show_admin_new_user: false,
  show_admin_new_partner: false,
  showExtend: false,
  showReEnroll: false,
  returnErrors: [],
};

export const getPartnerData = createAsyncThunk(
  "user/getPartnerData",
  async (thunkAPI) => {
    const accessToken = getAccessToken();

    try {
      const response = await fetch(api_path + `/user`, {
        mode: "cors",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${accessToken}`,
        },
      });
      if (!response.ok) {
        throw new Error("Unable to fetch user state from server.");
      }
      const data = await response.json();
      localStorage.setItem("CopperheadPartner_user", data?.user_name);
      localStorage.setItem("CopperheadPartner_name", data?.partner_name);
      localStorage.setItem("CopperheadPartner_id", data?.partner_id);
      if (data.show_credits_admin === true) {
        localStorage.setItem(
          "CopperheadDistributorPartner",
          data.show_credits_admin
        );
      }

      return data;
    } catch (error) {
      return thunkAPI.rejectWithValue(
        "You broke redux toolkit fetching the credits: " + error
      );
    }
  }
);

const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    toggleReEnrollDialog: {
      reducer: (state) => {
        state.showReEnroll = !state.showReEnroll
      }
    },
    toggleExtendDialog: {
      reducer: (state) => {
        state.showExtend = !state.showExtend;
      }
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getPartnerData.pending, (state) => {
      state.userdataLoading = "pending";
    });
    builder.addCase(getPartnerData.fulfilled, (state, action) => {
      state.userdataLoading = "idle";
      state.user_name = action.payload.user_name;
      state.partner_name = action.payload.partner_name;
      state.partner_id = action.payload.partner_id;
      state.show_credits_admin = action.payload?.show_credits_admin;
      state.show_admin_new_user = action.payload?.show_admin_new_user;
      state.show_admin_new_partner = action.payload?.show_admin_new_partner;
    });
    builder.addCase(getPartnerData.rejected, (state, action) => {
      state.userdataLoading = "idle";
      if (action.payload.status !== 401) {
        state.returnErrors = state.returnErrors.push(action.payload);
      }
    });
  },
});

export const userActions = userSlice.actions;

export default userSlice.reducer;
