import React from "react";
import { Divider, Typography } from "@mui/material";

const options = {
  weekday: "short",
  year: "numeric",
  month: "long",
  day: "numeric",
  timeZoneName: "short",
};

class CalendarDate extends React.Component {
  constructor(props) {
    super(props);
    this.state = { calendarDate: new Date() };
  }

  componentDidMount() {
    this.timerID = setInterval(() => this.tick(), 1000);
  }

  componentWillUnmount() {
    clearInterval(this.timerID);
  }

  tick() {
    this.setState({
      calendarDate: new Date(),
    });
  }

  render() {
    return (
      <React.Fragment>
        <Typography variant="caption">
          Local:{" "}
          {this.state.calendarDate.toLocaleDateString(undefined, options)}
        </Typography>
        <Divider variant="middle" />
        <Typography variant="caption">
          UTC Snapshot: {this.state.calendarDate.toUTCString()}
        </Typography>
      </React.Fragment>
    );
  }
}

export default CalendarDate;
