import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Box, Container, Typography } from "@mui/material";

import CalendarDate from "../../lib/classes/CalendarDate";
import CopperheadFallback from "../custom/CopperheadFallback";
import DisplayToolbar from "./DisplayToolbar";
import ErrorBoundary from "../../lib/classes/ErrorBoundary";

import CarouselViewAll from "./CarouselViewAll";
import GridViewAll from "./GridViewAll";
import CarouselViewCreators from "./CarouselViewCreators";
import GridViewCreators from "./GridViewCreators";
import { creditActions } from "../../store/credits/creditsSlice";

function CreditsMain() {
  const { updateCredits, creditsLoading, creditListing, showRenewals } = useSelector(
    (store) => store.credits
  );
  const { creditsLayout } = useSelector((store) => store.ui);
  const dispatch = useDispatch();

  React.useEffect(() => {
    if (creditsLoading === "pending") {
      return;
    }

    const creditInterval = setInterval(() => {
      dispatch(creditActions.setUpdateCredits());
    }, 60000);
    return () => clearInterval(creditInterval);
  }, [dispatch, updateCredits, creditsLoading]);

  if (creditsLoading === "pending") {
    return <CopperheadFallback />;
  }

  if (!creditListing) {
    return (
      <React.Fragment>
        <h2>
          Nice! Our web dev broke the product display with bad array mapping.
        </h2>
        <h4>
          The error has been automatically reported, we apologize for any
          inconvenience.
        </h4>
      </React.Fragment>
    );
  }

  return (
    <ErrorBoundary>
      <Container
        sx={{
          minHeight: "100%",
          height: "100%",
          padding: "10px",
          textAlign: "center",
        }}
      >
        <DisplayToolbar />
        <Typography variant="caption">
          <CalendarDate />
        </Typography>
        {showRenewals && (
          <ErrorBoundary>
            <Box>
              {creditsLayout === "carousel" && <CarouselViewAll />}
              {creditsLayout === "grid" && <GridViewAll />}
            </Box>
          </ErrorBoundary>
        )}
        {!showRenewals && (
          <ErrorBoundary>
            <Box>
              {creditsLayout === "carousel" && <CarouselViewCreators />}
              {creditsLayout === "grid" && <GridViewCreators />}
            </Box>
          </ErrorBoundary>
        )}
      </Container>
    </ErrorBoundary>
  );
}

export default CreditsMain;
