import { Auth, Hub } from "aws-amplify";
import { signinActions } from "./signinSlice";

export default class AmplifyBridge {
  constructor(store) {
    this.store = store;

    Hub.listen("auth", (data) => {
      const { payload } = data;
      this.onAuthEvent(payload);
    });

    this.checkUser(); 
  }

  onAuthEvent(payload) {
    if (payload.event === 'signIn_failure') {
      return;
    } else if (payload.event === "signIn") {
      this.checkUser();
    }
  }

  checkUser() {
    Auth.currentAuthenticatedUser()
      .then((user) => {
        const token = user.signInUserSession.accessToken.jwtToken;
        const expires = user.signInUserSession.accessToken.payload.exp;
        const issued = user.signInUserSession.accessToken.payload.iat;
        const name = user.username;
        this.store.dispatch(signinActions.setCognitoUser({
          username: name,
          accessToken: token,
          sessionExpiry: expires,
          sessionIat: issued,
        }));
      })
      .catch((err) => {
        console.info(err)
        this.store.dispatch(signinActions.logout());
      });
  }
}
