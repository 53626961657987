import React from "react";
import { useDispatch } from "react-redux";
import { styled, useTheme } from '@mui/material/styles';
import { Switch } from '@mui/material';
import { mdiMoonWaxingCrescent, mdiWeatherSunny } from "@mdi/js";
import { uiActions } from "../../store/UI/uiSlice";

const PaletteSwitch = styled(Switch)(({ theme }) => ({
  width: "62px",
  height: "34px",
  padding: "7px",
  '& .MuiSwitch-switchBase': {
    margin: "1px",
    padding: "0px",
    transform: 'translateX(6px)',
    '&.Mui-checked': {
      color: '#fff',
      transform: 'translateX(22px)',
      '& .MuiSwitch-thumb:before': {
        backgroundImage: theme.palette.mode === 'dark' ? `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="20" width="20" viewBox="3 3 20 20"><path fill="${encodeURIComponent(
          '#fff',
        )}" d="${mdiMoonWaxingCrescent}"/></svg>')` : `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="20" width="20" viewBox="2 2 20 20"><path fill="${encodeURIComponent(
          '#231f20',
        )}" d="${mdiWeatherSunny}" /></svg>')`,
      },
      '& + .MuiSwitch-track': {
        opacity: 1,
        backgroundColor: theme.palette.mode === 'dark' ? '#8796A5' : '#aab4be',
      },
    },
  },
  '& .MuiSwitch-thumb': {
    backgroundColor: theme.palette.mode === 'dark' ? '#231f20' : '#63a526',
    width: "32px",
    height: "32px",
    '&:before': {
      content: "''",
      position: 'absolute',
      width: '100%',
      height: '100%',
      left: 0,
      top: 0,
      backgroundRepeat: 'no-repeat',
      backgroundPosition: 'center',
      backgroundImage: theme.palette.mode === 'dark' ? `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="20" width="20" viewBox="2 2 20 20"><path fill="${encodeURIComponent(
        '#fff',
      )}" d="${mdiMoonWaxingCrescent}" /></svg>')` : `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="20" width="20" viewBox="2 2 20 20"><path fill="${encodeURIComponent(
        '#231f20',
      )}" d="${mdiWeatherSunny}" /></svg>')`,
    },
  },
  '& .MuiSwitch-track': {
    opacity: 1,
    backgroundColor: theme.palette.mode === 'dark' ? '#8796A5' : '#aab4be',
    borderRadius: 20 / 2,
  },
}));

function PaletteModeSwitch() {
  const dispatch = useDispatch();
  const theme = useTheme();

  const handlePalette = () => {
    if (theme.palette.mode === "dark") {
      dispatch(uiActions.togglePaletteMode("light"));
    } else if (theme.palette.mode === "light") {
      dispatch(uiActions.togglePaletteMode("dark"));
    }
  };

  return (
    <PaletteSwitch onChange={handlePalette} />
  );
}

export default PaletteModeSwitch;
