import React from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { DataGrid } from "@mui/x-data-grid";
import { Alert, Box } from "@mui/material";
import ErrorBoundary from "../../lib/classes/ErrorBoundary";
import CustomToolbar from "../custom/CustomToolbar";
import { subscriptionActions } from "../../store/subscriptions/subscriptionsSlice";
import EnrollStatus from "./EnrollStatus";
import { Lock } from "@mui/icons-material";

const SubscriptionList = () => {
  const [dismiss, setDismiss] = React.useState(false);
  const { subsListing, subsLoading, loadingStateMachine, updateSubs } =
    useSelector((store) => store.subs);
  const [pageSizeIs, setPageSize] = React.useState(15);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const columns = [
    {
      field: "identifier",
      headerName: "Account Tag",
      flex: 1,
      width: "36vw",
      editable: false,
      description: "Associate customer with licence",
    },
    {
      field: "initial_claim_used",
      headerName: "Enroll Status",
      flex: 0.17,
      width: "12.4vw",
      description:
        "Displays licence enrollment status. Open Lock means un-enrolled. Closed, green lock means fully enrolled with a device. Closed, yellow lock means awaiting re-enroll.",
      editable: false,
      align: "center",
      renderCell: (params) => {
        return (
          <EnrollStatus
            initial_claim_used={params.row.initial_claim_used}
            currently_claimed={params.row.currently_claimed}
          />
        );
      },
    },
    {
      field: "start_date",
      headerName: "Claim Date",
      flex: 0.2,
      width: "14.4vw",
      type: "dateTime",
      description: "Date licence was claimed by device.",
      editable: false,
    },
    {
      field: "expiry_date",
      headerName: "Expiry Date",
      flex: 0.2,
      width: "14.4vw",
      type: "dateTime",
      description: "Date licence will expire.",
      editable: false,
    },
    {
      field: "status",
      headerName: "Licence Status",
      flex: 0.2,
      width: "14.4vw",
      description: "Current licence status",
      editable: false,
    },
    {
      field: "product_name",
      headerName: "Product Type",
      flex: 0.2,
      width: "14.4vw",
      description: "Product Type",
      editable: false,
      hideable: true,
    },
    {
      field: "licence_id",
      headerName: "Licence ID",
      flex: 0.2,
      width: "14.4vw",
      description: "Licence ID",
      editable: false,
      hide: true,
    },
  ];

  React.useLayoutEffect(() => {
    const savedSubGridPageSize = localStorage.getItem("sub-grid-pagesize");

    if (savedSubGridPageSize) {
      setPageSize(+savedSubGridPageSize);
    }

    return () => {};
  }, []);

  React.useEffect(() => {
    if (loadingStateMachine === "pending") {
      return;
    }

    const fastSubsInterval = setInterval(() => {
      dispatch(subscriptionActions.setUpdateSubs());
    }, 60000);

    return () => clearInterval(fastSubsInterval);
  }, [dispatch, updateSubs, loadingStateMachine]);

  React.useEffect(() => {
    const persistedDismissal = localStorage.getItem("new_lock_alert");

    if (persistedDismissal) {
      setDismiss(persistedDismissal);
    }
  },[]);

  const handleDismissal = (event) => {
    event.preventDefault();
    setDismiss(true);
    localStorage.setItem("new_lock_alert", true);
  }

  return (
    <ErrorBoundary>
      <React.Fragment>
        {!dismiss && <Alert
          icon={<Lock />}
          variant="outlined"
          severity="success"
          sx={{ mx: "2px", p: "1px" }}
          onClose={handleDismissal}
        >
          Lock Icons now correctly display licence enrollment status: Open Lock
          means unclaimed (not used). The closed green lock means claimed and
          enrolled with a device. The closed yellow lock means claimed and
          awaiting re-enroll.
        </Alert>}
        <Box sx={{ height: "85vh", width: "auto", mx: "0.5px" }}>
          <DataGrid
            loading={subsLoading}
            components={{
              Toolbar: CustomToolbar,
            }}
            componentsProps={{
              toolbar: {
                header: "Subscriptions and Licences",
                showRefresh: true,
                refreshSubs: true,
              },
            }}
            density="compact"
            rows={subsListing}
            columns={columns}
            rowBuffer={10}
            pageSize={pageSizeIs}
            rowsPerPageOptions={[5, 10, 15, 25, 50, 75, 100]}
            disableSelectionOnClick
            onPageSizeChange={(pageSize) => {
              localStorage.setItem("sub-grid-pagesize", pageSize);
              setPageSize(pageSize);
            }}
            onRowClick={(params) => {
              navigate(params.id + "/");
            }}
            sx={{
              boxShadow: 1,
              border: 1,
              borderColor: "primary.dark",
              "& .MuiDataGrid-columnSeparator": {
                color: "primary.dark",
              },
              "& .MuiDataGrid-cell:hover": {
                color: "primary.main",
              },
            }}
          />
        </Box>
      </React.Fragment>
    </ErrorBoundary>
  );
};

export default SubscriptionList;
