const gridBox = {
  justifyContent: "space-between",
  alignContent: "space-between",
  mt: 2,
  display: "inline-block",
  mx: "2px",
  minWidth: "36vw",
  width: "fit-content",
  height: "46vh",
};

export default gridBox;