import React from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Button,
  Card,
  CardActions,
  CardContent,
  MenuItem,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { AddModerator, Close } from "@mui/icons-material";
import { adminActions, assignCredits } from "../../store/admin/adminSlice";
import { enqueueSnackbar } from "../../store/notifications/actions";

const AddCredits = (props) => {
  const { partner_id, business_name, kind, base_product, type } =
    React.useDeferredValue(props);
  const { adminBaseProds, adminProdsSorted } = useSelector(
    (store) => store.admin
  );
  const dispatch = useDispatch();
  const [baseVal, setBaseVal] = React.useState(`${base_product}`);
  const [typeVal, setTypeVal] = React.useState(`${type}` || "nil");
  const [submitValue, setSubmitValue] = React.useState(+kind);
  const [cvalue, setCValue] = React.useState("");
  const [disable, setDisable] = React.useState(true);
  const baseValRef = React.useRef();
  const typeValRef = React.useRef();
  const submitValueRef = React.useRef();
  const qtyRef = React.useRef();

  const handleQuantity = (event) => {
    if (event.target.value >= 0) {
      setCValue(event.target.value);
    }
    if (event.target.value > 0 && submitValueRef.current.value > 0) {
      setDisable(false);
    }
  };

  const handleBlur = (event) => {
    if (!qtyRef.current.value || !submitValueRef.current.value) {
      setDisable(true);
    }

    if (qtyRef.current.value > 0 && submitValueRef.current.value > 0) {
      setDisable(false);
    }
  };

  const handleBaseVal = (event) => {
    setBaseVal(event.target.value);
    setTypeVal("nil");
    setSubmitValue(0);
    if (!qtyRef.current.value || !submitValueRef.current.value) {
      setDisable(true);
    }

    if (qtyRef.current.value > 0 && submitValueRef.current.value > 0) {
      setDisable(false);
    }
  };

  const handleTypeVal = (event) => {
    setTypeVal(event.target.value);
    const baseIndex = adminProdsSorted.findIndex(
      (element) => element.base === baseVal
    );

    if (event.target.value === "renew") {
      const renewKind = adminProdsSorted[baseIndex].products.renewals[0]?.kind;
      if (renewKind) {
        setSubmitValue(renewKind);
      } else {
        setSubmitValue(0);
      }
    }

    if (event.target.value === "create") {
      const createKind = adminProdsSorted[baseIndex].products.creators[0]?.kind;
      if (createKind) {
        setSubmitValue(createKind);
      } else {
        setSubmitValue(0);
      }
    }

    if (event.target.value === "nil") {
      setSubmitValue(0);
    }

    if (!qtyRef.current.value || !submitValueRef.current.value) {
      setDisable(true);
    }

    if (qtyRef.current.value > 0 && submitValueRef.current.value > 0) {
      setDisable(false);
    }
  };

  const handleSubmitValue = (event) => {
    setSubmitValue(event.target.value);
  };

  const typeValHelper = (value) => {
    if (value === "create") {
      return "New";
    }
    if (value === "renew") {
      return "Renew";
    }
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    if (qtyRef.current.value <= 0) {
      dispatch(
        enqueueSnackbar({
          message: "You probably want to assign more than zero (0) credits.",
          options: {
            variant: "warning",
            autoHideDuration: 12000,
            resumeHideDuration: 800,
          },
        })
      );

      return;
    }

    if (submitValueRef.current.value <= 0) {
      dispatch(
        enqueueSnackbar({
          message: `Please check that you have a product properly selected.`,
          options: {
            variant: "warning",
            autoHideDuration: 12000,
            resumeHideDuration: 800,
          },
        })
      );

      return;
    }

    const prodInput = submitValueRef.current.value;
    const qtyInput = qtyRef.current.value;
    const mangle = `{ "partner_id": ${+partner_id}, "kind": ${+prodInput}, "qty": ${+qtyInput} }`;
    const realJson = JSON.parse(mangle);

    dispatch(assignCredits(realJson));
    dispatch(adminActions.setUpdateAdmin());
    dispatch(adminActions.handleAddDialog());
  };

  const handleClose = (event) => {
    event.preventDefault();

    dispatch(adminActions.handleAddDialog());
  };

  return (
    <Card>
      <CardContent>
        <Typography variant="h5">
          Partner: {business_name}, #{partner_id}{" "}
        </Typography>
        <Stack direction="row">
          <TextField
            id="select-distributor-base-products"
            select
            label="Base Product"
            value={baseVal}
            onChange={handleBaseVal}
            inputRef={baseValRef}
            onBlur={handleBlur}
            helperText="Select a base product package?"
            sx={{ my: 2 }}
          >
            {adminBaseProds.map((baseProd) => (
              <MenuItem key={baseProd} value={baseProd}>
                {baseProd.toString()}
              </MenuItem>
            ))}
          </TextField>
          <TextField
            id="select-distributor-product-type"
            select
            label="Product Type"
            helperText="Select New or Renew packages?"
            value={typeVal}
            onChange={handleTypeVal}
            inputRef={typeValRef}
            onBlur={handleBlur}
            sx={{ my: 2 }}
          >
            <MenuItem value="nil">--</MenuItem>
            <MenuItem value="create">New</MenuItem>
            <MenuItem value="renew">Renew</MenuItem>
          </TextField>
        </Stack>
        <Stack direction="row">
          {baseVal === "" && typeVal === "nil" && (
            <TextField
              id="outlined-select-null-base-type"
              select
              label="Select a base product & type."
              helperText="Select a base product & type."
              sx={{ my: 2 }}
              onBlur={handleBlur}
              value={0}
            >
              <MenuItem value={0}>Select a base product & type.</MenuItem>
            </TextField>
          )}
          {baseVal !== "" && typeVal === "nil" && (
            <TextField
              id="outlined-select-null-type"
              select
              label="Select a type"
              helperText="Select a type"
              sx={{ my: 2 }}
              onBlur={handleBlur}
              value={0}
            >
              <MenuItem value={0}>Select a type.</MenuItem>
            </TextField>
          )}
          {baseVal === "" && typeVal !== "nil" && (
            <TextField
              id="outlined-select-null-base"
              select
              label="Select a base product."
              helperText="Select a base product."
              sx={{ my: 2 }}
              value={0}
            >
              <MenuItem value={0}>Select a base product.</MenuItem>
            </TextField>
          )}
          {baseVal !== "" && typeVal !== "nil" && (
            <TextField
              select
              id={`select-distributor-${baseVal}-${typeVal}`}
              label={`${baseVal} ${typeValHelper(typeVal)}`}
              helperText={`Select ${baseVal} ${typeValHelper(typeVal)}?`}
              value={submitValue}
              onChange={handleSubmitValue}
              onBlur={handleBlur}
              inputRef={submitValueRef}
              sx={{ my: 2 }}
            >
              <MenuItem value={0}>--</MenuItem>
              {typeVal === "renew" &&
                adminProdsSorted[
                  adminProdsSorted.findIndex(
                    (element) => element.base === baseVal
                  )
                ].products.renewals.map((credit) => (
                  <MenuItem key={credit.kind} value={credit.kind}>
                    {credit.description}
                  </MenuItem>
                ))}
              {typeVal === "create" &&
                adminProdsSorted[
                  adminProdsSorted.findIndex(
                    (element) => element.base === baseVal
                  )
                ].products.creators.map((credit) => (
                  <MenuItem key={credit.kind} value={credit.kind}>
                    {credit.description}
                  </MenuItem>
                ))}
            </TextField>
          )}
          <TextField
            value={cvalue}
            type="number"
            inputProps={{ inputMode: "numeric", pattern: "[0-9]*" }}
            id="filled-credit-quant"
            label="Credits to add..."
            onChange={handleQuantity}
            onBlur={handleBlur}
            inputRef={qtyRef}
            helperText="Select the number of credits to add."
            sx={{ my: 2 }}
            variant="filled"
          />
        </Stack>
      </CardContent>
      <CardActions sx={{ justifyContent: "space-between" }}>
        <Button
          endIcon={<Close />}
          variant="contained"
          sx={{ p: 1 }}
          onClick={handleClose}
        >
          Cancel
        </Button>
        <Button
          disabled={disable}
          startIcon={<AddModerator />}
          variant="contained"
          sx={{ p: 1 }}
          onClick={handleSubmit}
        >
          Confirm
        </Button>
      </CardActions>
    </Card>
  );
};

export default AddCredits;
