import { createSlice, nanoid } from "@reduxjs/toolkit";

export const ENQUEUE_SNACKBAR = "enqueueSnackbar";
export const CLOSE_SNACKBAR = "closeSnackbar";
export const REMOVE_SNACKBAR = "removeSnackbar";
export const TOGGLE_NOTIFIER_MENU = "toggleNotifierMenu";
export const DISMISS_HISTORY = "dismissHistory";

const notifierSlice = createSlice({
  name: "notifier",
  initialState: { notifications: [], lastTen: [], showNotifierMenu: false },
  reducers: {
    [ENQUEUE_SNACKBAR]: {
      reducer: (state, { payload }) => {
        state.notifications.push(payload);
        if (state.lastTen.length >= 11) {
          const guide = state.lastTen.length - 9;
          state.lastTen.slice(guide);
        }
        state.lastTen.push(payload);
      },
      prepare: (notification) => {
        const key = nanoid();
        const payload = { ...notification, key: key };
        return { payload };
      },
    },
    [CLOSE_SNACKBAR]: {
      reducer: (state, action) => {
        const { payload } = action;
        state.notifications = state.notifications.map((notification) => {
          const shouldDismiss =
            payload.dismissAll === true || notification.key === payload.key;
          return shouldDismiss
            ? { ...notification, dismissed: true }
            : { ...notification };
        });
      },
      prepare: (key) => ({ payload: { key, dismissAll: !key } }),
    },
    [REMOVE_SNACKBAR]: (state, { payload }) => {
      state.notifications = state.notifications.filter(
        (notification) => notification.key !== payload
      );
    },
    [TOGGLE_NOTIFIER_MENU]: (state) => {
      state.showNotifierMenu = !state.showNotifierMenu;
    },
    [DISMISS_HISTORY]: (state, { payload }) => {
      state.lastTen = state.lastTen.filter(
        (historic) => historic.key !== payload
      );
    },
  },
});

export default notifierSlice;
