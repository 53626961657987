import { createTheme } from "@mui/material/styles";

export const lightMode = createTheme({
  palette: {
    mode: "light",
    primary: {
      main: "#63a528",
      contrastText: "#fff",
    },
    secondary: {
      main: "#63a528",
      contrastText: "#fff",
    },
    divider: "#63a528",
    background: {
      default: "#ededed",
      paper: "#e3e3e3",
    },
  },
  props: {
    MuiTooltip: {
      arrow: true,
    },
  },
  typography: {
    fontFamily: "Fira Sans",
    textShadow: "1px 1px 1px whitesmoke",
    h1: {
      fontFamily: "Big Shoulders Text",
      fontWeight: 500,
      textShadow: "1px 1px 1px whitesmoke",
    },
    h2: {
      fontFamily: "Big Shoulders Text",
      fontWeight: 500,
      textShadow: "1px 1px 1px whitesmoke",
    },
    h3: {
      fontFamily: "Big Shoulders Text",
      fontWeight: 500,
      textShadow: "1px 1px 1px whitesmoke",
    },
    h4: {
      fontFamily: "Big Shoulders Text",
      fontWeight: 600,
      lineHeight: 1.2,
      textShadow: "1px 1px 1px whitesmoke",
    },
    h5: {
      fontFamily: "Big Shoulders Text",
      fontWeight: 600,
      lineHeight: 1.25,
      textShadow: "1px 1px 1px whitesmoke",
    },
    h6: {
      fontFamily: "Big Shoulders Text",
      fontWeight: 600,
      lineHeight: 1.25,
      textShadow: "1px 1px 1px whitesmoke",
    },
  },
  components: {
    MuiUseMediaQuery: {
      defaultProps: {
        noSsr: true,
      },
    },
    MuiLink: {
      styleOverrides: {
        root: {
          textDecoration: "none",
        },
      },
    },
    MuiListItemText: {
      styleOverrides: {
        fontFamily: "Big Shoulders Text",
        fontWeight: 600,
      },
    },
    MuiAppBar: {
      styleOverrides: {
        bgcolor: "primary.main",
      },
    },
  },
});
