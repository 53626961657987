import React from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Button,
  Card,
  Container,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  Stack,
  Typography,
} from "@mui/material";
import { api_path } from "../../config/site";
import { userActions } from "../../store/user/userSlice";
import { Close, LockOpen } from "@mui/icons-material";
import { useDeferredValue } from "react";
import { enqueueSnackbar } from "../../store/notifications/actions";

const ReEnrollDialog = (props) => {
  const { subscriptionId, identifier,  } = useDeferredValue(props);
  const { accessToken } = useSelector((store) => store.signin.userSession);
  const { showReEnroll } = useSelector((store) => store.user);
  const dispatch = useDispatch();

  const handleClose = () => {
    dispatch(userActions.toggleReEnrollDialog());
  };

  const handleReEnroll = async (event) => {
    event.preventDefault();

    try {
      const response = await fetch(
        api_path + `/subscription/${subscriptionId}/re-enroll`,
        {
          mode: "cors",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );
      if (!response.ok) {
        throw new Error("Unable to retrieve re-enroll permission.");
      }

      const data = await response.json();
      if (data === "Success.") {
        dispatch(userActions.toggleReEnrollDialog());
        dispatch(
          enqueueSnackbar({
            message: "Your QR code has been released for re-enrolling devices!",
            options: {
              variant: "success",
              autoHideDuration: 12000,
              resumeHideDuration: 800,
            },
          })
        );
      }
    } catch (e) {
      console.error(e);
      dispatch(userActions.toggleReEnrollDialog());
      dispatch(
        enqueueSnackbar({
          message:
            "This QR code could not be released for re-enroll. Are you sure it has been claimed?",
          options: {
            variant: "warning",
            autoHideDuration: 12000,
            resumeHideDuration: 800,
          },
        })
      );
    }

  };

  return (
    <Container>
      <Dialog
        maxWidth="md"
        disableEnforceFocus
        open={showReEnroll}
        onClose={handleClose}
        aria-labelledby="modal-title"
        aria-describedby="modal-description"
        sx={{ justifyContent: "center" }}
      >
        <Card sx={{ backgroundColor: "#030303" }}>
          <DialogTitle>
            <Stack direction="row">
              <Typography id="modal-title" variant="inherit" sx={{ ml: "2px" }}>
                Re-enroll subscription:
              </Typography>
              <Typography
                variant="inherit"
                sx={{ color: "#63a528", ml: "3px" }}
              >
                {" "}
                {identifier}{" "}
              </Typography>
            </Stack>
            <Divider variant="middle" sx={{ my: "0.5px" }} />
          </DialogTitle>
          <DialogContent
            sx={{
              m: "2px",
              alignText: "center",
              justifyContent: "space-between",
            }}
          >
            <DialogContentText id="modal-description" variant="body2">
              This action will allow a licence to be Re-Enrolled on the same device, or a new device using the QR code.
              If the licence is in use, the end user will receive a notification about this action. Ensure that the device
              and user you wish to Re-Enroll are ready to proceed.

              Typical use: A factory reset / wiped device to be re-enrolled.
            </DialogContentText>
          </DialogContent>
          <DialogActions sx={{ justifyContent: "space-between" }}>
            <Button
              variant="contained"
              onClick={handleClose}
              startIcon={<Close />}
            >
              Cancel
            </Button>
            <Button
              variant="contained"
              onClick={handleReEnroll}
              endIcon={<LockOpen />}
            >
              Re-Enroll
            </Button>
          </DialogActions>
        </Card>
      </Dialog>
    </Container>
  );
};

export default ReEnrollDialog;
