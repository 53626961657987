import React from "react";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  Button,
  Card,
  IconButton,
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Tooltip,
  Typography,
  useMediaQuery,
} from "@mui/material";
import expiryConverter from "../../store/subscriptions/expiryConverter";
import fromHeader from "../../store/subscriptions/fromHeader";
import { enqueueSnackbar } from "../../store/notifications/actions";
import { userActions } from "../../store/user/userSlice";
import { api_path } from "../../config/site";
import CopperheadFallback from "../custom/CopperheadFallback";
import SubToolbar from "./SubToolbar";
import QrViewer from "./QrViewer";
import { useTheme } from "@emotion/react";
import { ContentCopy, Refresh } from "@mui/icons-material";
import EnrollStatus from "./EnrollStatus";
import "./RefreshBtn.css";

const initialSubData = {
    id: 0,
    licence_id: "",
    start_date: "",
    expiry_date: "",
    enduser: 0,
    distributor: 0,
    initial_claim_used: false,
    identifier: "",
    currently_claimed: false,
}

const SubscriptionDetail = () => {
  const { userSession } = useSelector((store) => store.signin);
  const idInputRef = React.useRef("");
  const dispatch = useDispatch();
  const params = useParams();
  const { subscriptionId } = params;
  const [updateSubInfo, setUpdateSubInfo] = React.useState(true);
  const [loading, setLoading] = React.useState(true);
  const [error, setError] = React.useState({});
  const [qrLoading, setQRLoading] = React.useState(true);
  const [qrError, setQRError] = React.useState({});
  const [saveDisabled, setSaveDisabled] = React.useState(true);
  const [subData, setSubData] = React.useState(initialSubData);
  const [url, setImgUrl] = React.useState("");
  const theme = useTheme();
  const mdScreen = useMediaQuery(theme.breakpoints.up("md"));
  const lgScreen = useMediaQuery(theme.breakpoints.up("lg"));
  const xlScreen = useMediaQuery(theme.breakpoints.up("xl"));

  const handleOpen = () => {
    dispatch(userActions.toggleExtendDialog());
  };

  const handleChange = () => {
    setSaveDisabled(false);
  };

  const handleBlur = () => {
    idInputRef.current.value = idInputRef.current.value.trim();
    if (!idInputRef.current.value) {
      setSaveDisabled(true);
    }
  };

  const handleReEnrollDialog = () => {
    dispatch(userActions.toggleReEnrollDialog());
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    const current = idInputRef.current.value.trim();

    if (current.length < 6) {
      idInputRef.current.value = "";
      dispatch(
        enqueueSnackbar({
          message: "You must use at least 6 characters for an identifier.",
          options: {
            variant: "warning",
            autoHideDuration: 12000,
            resumeHideDuration: 800,
          },
        })
      );
      return;
    }

    const template = `{"UpdateAcctTag": { "sub_id": ${subscriptionId}, "new_tag": "${current}" }}`;
    const payload = JSON.parse(template);
    try {
      const response = await fetch(
        api_path + `/subscription/${subscriptionId}/info`,
        {
          method: "POST",
          mode: "cors",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${userSession.accessToken}`,
          },
          body: JSON.stringify(payload),
        }
      );
      if (!response.ok) {
        throw new Error("Could not update partner identifier");
      }
      await response
        .json()
        .then(
          dispatch(
            enqueueSnackbar({
              message: `Account tag updated to ${current} !`,
              options: {
                variant: "success",
                autoHideDuration: 12000,
                resumeHideDuration: 800,
              },
            })
          )
        )
        .then(setUpdateSubInfo(true));
    } catch (error) {
      console.error(error);
      dispatch(
        enqueueSnackbar({
          message: "Account tag could not be updated",
          options: {
            variant: "error",
            autoHideDuration: 12000,
            resumeHideDuration: 800,
          },
        })
      );
    }
  };

  const handleClipboard = () => {
    navigator.clipboard
      .writeText(subData.identifier)
      .then(() => {
        dispatch(
          enqueueSnackbar({
            message: `Identifier ${subData.identifier} copied to clipboard.`,
            options: {
              variant: "success",
              autoHideDuration: 12000,
              resumeHideDuration: 800,
            },
          })
        );
      })
      .catch(() => {
        dispatch(
          enqueueSnackbar({
            message: "Unable to write to clipboard!",
            options: {
              variant: "warning",
              autoHideDuration: 12000,
              resumeHideDuration: 800,
            },
          })
        );
      });
  };

  const handleRefresh = () => {
    setUpdateSubInfo(true);
  };

  React.useEffect(() => {
    const controller = new AbortController();
    const getSubInfo = async () => {
      setLoading(true);
      setError(null);
      try {
        const response = await fetch(
          api_path + `/subscription/${subscriptionId}/info`,
          {
            signal: controller.signal,
            mode: "cors",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${userSession.accessToken}`,
            },
          }
        );
        const data = await response.json();
        if (!response.ok) {
          throw new Error(data.message || "Unable to retrieve sub information");
        }
        const productMetadata = fromHeader(data.licence_id);
        const sub = Object.assign(data, productMetadata);
        if (sub.expiry_date) {
          const expiryMetadata = expiryConverter(sub.expiry_date);
          Object.assign(sub, expiryMetadata);
          setSubData(sub);
        } else {
          setSubData(sub);
        }
      } catch (error) {
        if (error.name === "AbortError") {
          console.info("Extra fetch aborted.");
        } else {
          setError(error);
        }
      }
      setLoading(false);
      setUpdateSubInfo(false);
    };

    if (updateSubInfo) {
      getSubInfo().then(() => {
        console.info("Subscription info updated.");
      }).catch((error) => {
        console.error(error);
      });
    }

    return () => controller.abort();
  }, [subscriptionId, userSession.accessToken, updateSubInfo]);

  React.useEffect(() => {
    if (loading) {
      return;
    }

    const controller = new AbortController();
    const handleImgFetch = async () => {
      setQRLoading(true);
      setQRError(null);
      try {
        const response = await fetch(
          api_path + `/subscription/${subscriptionId}/qrcode`,
          {
            signal: controller.signal,
            mode: "cors",
            headers: {
              "Content-Type": "image/png",
              Authorization: `Bearer ${userSession.accessToken}`,
            },
          }
        );
        if (!response.ok) {
          throw new Error("Unable to retrieve QR Code.");
        }
        const blobData = await response.blob();
        const imgUrl = URL.createObjectURL(blobData);
        setImgUrl(imgUrl);
      } catch (error) {
        if (error.name === "AbortError") {
          console.info("Extra fetch aborted.");
        } else {
          setQRError(error.message);
          console.error(error);
          dispatch(
            enqueueSnackbar({
              message: "We could not retrieve QR code data.",
              options: {
                variant: "warning",
                autoHideDuration: 12000,
                resumeHideDuration: 800,
              },
            })
          );
        }
      }
      setQRLoading(false);
    };
    handleImgFetch().then(() => {
      console.info("Subscription info updated.");
    }).catch((error) => {
      console.error(error);
    });
    return () => controller.abort();
  }, [dispatch, subscriptionId, userSession.accessToken, loading]);

  if (loading) {
    return <CopperheadFallback />;
  }

  if (error) {
    return (
      <h4>
        There was an error retrieving subscription data. Please try again.
      </h4>
    );
  }
  if (!mdScreen || !lgScreen || !xlScreen) {
    return (
      <Card elevation={5} sx={{ width: "98%", height: "auto" }}>
        <Stack direction="column" sx={{ p: 1, alignContent: "flex-start" }}>
          <Stack direction="row" sx={{ justifyContent: "space-between" }}>
            <TextField
              label={`Current identifier: ${subData.identifier}`}
              placeholder={subData.identifier}
              helperText="Update licence identifier?"
              FormHelperTextProps={{ sx: { textAlign: "right" } }}
              InputProps={{
                endAdornment: (
                  <Tooltip title="Copy current identifier to clipboard">
                    <IconButton onClick={handleClipboard}>
                      <ContentCopy />
                    </IconButton>
                  </Tooltip>
                ),
              }}
              id="identifier"
              onChange={handleChange}
              onBlur={handleBlur}
              autoComplete="organization"
              name="identifier"
              sx={{ mx: 1, height: "6rem" }}
              fullWidth={true}
              inputRef={idInputRef}
            />
            <Button
              onClick={handleSubmit}
              disabled={saveDisabled}
              type="submit"
              id="submit"
              name="submit"
              variant="outlined"
              sx={{ m: 1, height: "2rem" }}
            >
              Save
            </Button>
          </Stack>
          <TableContainer component={Paper}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>First claimed on</TableCell>
                  <TableCell>Enroll Status</TableCell>
                  <TableCell>Expiry date</TableCell>
                  {subData.duration && <TableCell>Period paid</TableCell>}
                  {subData.deficit && <TableCell>Period since lapse</TableCell>}
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow>
                  <TableCell>{subData?.start_date || "Unclaimed"}</TableCell>
                  <TableCell>
                    <EnrollStatus
                      initial_claim_used={subData.initial_claim_used}
                      currently_claimed={subData.currently_claimed}
                    />
                  </TableCell>
                  <TableCell>
                    {subData?.expiry_date || "Expiry undefined"}
                  </TableCell>
                  {subData.duration && (
                    <TableCell>{subData.readable}</TableCell>
                  )}
                  {subData.deficit && <TableCell>{subData.readable}</TableCell>}
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
          <Stack direction="row" sx={{ p: 1, justifyContent: "center" }}>
            {subData.duration && (
              <Typography variant="h6" sx={{ m: 1, color: "lightgreen" }}>
                {subData.readable} of paid updates remaining!
              </Typography>
            )}
            {subData.deficit && (
              <Typography variant="h6" sx={{ m: 1, color: "orange" }}>
                {subData.readable} without updates!
              </Typography>
            )}
          </Stack>
          <Stack direction="row" sx={{ justifyContent: "center" }}>
            <SubToolbar
              subscriptionId={subscriptionId}
              subData={subData}
              setUpdateSubInfo={setUpdateSubInfo}
              handleOpen={handleOpen}
              qrError={qrError}
              qrLoading={qrLoading}
              handleReEnrollDialog={handleReEnrollDialog}
              url={url}
            />
          </Stack>
          <Stack direction="row" sx={{ justifyContent: "center" }}>
            <QrViewer url={url} qrError={qrError} qrLoading={qrLoading} />
          </Stack>
          <Stack direction="row" sx={{ justifyContent: "center" }}>
            <Typography>Licence ID serial: {subData.licence_id}</Typography>
          </Stack>
        </Stack>
      </Card>
    );
  }

  return (
    <Card elevation={5} sx={{ width: "98%", height: "auto" }}>
      <Stack direction="column" sx={{ p: 1, alignContent: "flex-start" }}>
        <Stack direction="row" sx={{ justifyContent: "space-between" }}>
          <TextField
            label={`Current identifier: ${subData.identifier}`}
            placeholder={subData.identifier}
            helperText="Update licence identifier?"
            FormHelperTextProps={{ sx: { textAlign: "right" } }}
            InputProps={{
              endAdornment: (
                <Tooltip title="Copy current identifier to clipboard">
                  <IconButton onClick={handleClipboard}>
                    <ContentCopy />
                  </IconButton>
                </Tooltip>
              ),
            }}
            id="identifier"
            onChange={handleChange}
            onBlur={handleBlur}
            autoComplete="organization"
            name="identifier"
            sx={{ mx: 1, height: "6rem" }}
            fullWidth={true}
            inputRef={idInputRef}
          />
          <Button
            onClick={handleSubmit}
            disabled={saveDisabled}
            type="submit"
            id="submit"
            name="submit"
            variant="outlined"
            sx={{ m: 1, height: "2rem" }}
          >
            Save
          </Button>
        </Stack>
        <TableContainer component={Paper}>
          <Table align="center">
            <TableHead>
              <TableRow>
                <TableCell>First claimed on</TableCell>
                <TableCell>Enroll status</TableCell>
                <TableCell>Expiry date</TableCell>
                {subData.duration && <TableCell>Period paid</TableCell>}
                {subData.deficit && <TableCell>Period since lapse</TableCell>}
                <TableCell align="center">Refresh data</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow>
                <TableCell>{subData?.start_date || "Unclaimed"}</TableCell>
                <TableCell>
                  <EnrollStatus
                    initial_claim_used={subData.initial_claim_used}
                    currently_claimed={subData.currently_claimed}
                  />
                </TableCell>
                <TableCell>
                  {subData?.expiry_date}
                </TableCell>
                {subData.duration && <TableCell>{subData.readable}</TableCell>}
                {subData.deficit && <TableCell>{subData.readable}</TableCell>}
                <TableCell align="center">
                  {" "}
                  <IconButton
                    aria-label="Refresh content"
                    className="refresh-btn"
                    onClick={handleRefresh}
                  >
                    <Refresh
                      sx={{ color: "#63A528" }}
                      className="refresh-icon"
                    />
                  </IconButton>
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
        <Stack direction="row" sx={{ p: 1, justifyContent: "center" }}>
          {subData.duration && (
            <Typography variant="h6" sx={{ m: 1, color: "lightgreen" }}>
              {subData.readable} of paid updates remaining!
            </Typography>
          )}
          {subData.deficit && (
            <Typography variant="h6" sx={{ m: 1, color: "orange" }}>
              {subData.readable} without updates!
            </Typography>
          )}
        </Stack>
        <Stack direction="row" sx={{ justifyContent: "center" }}>
          <SubToolbar
            subscriptionId={subscriptionId}
            subData={subData}
            setUpdateSubInfo={setUpdateSubInfo}
            handleOpen={handleOpen}
            qrError={qrError}
            qrLoading={qrLoading}
            handleReEnrollDialog={handleReEnrollDialog}
            url={url}
          />
          <QrViewer url={url} qrError={qrError} qrLoading={qrLoading} />
        </Stack>
        <Stack direction="row" sx={{ justifyContent: "center" }}>
          <Typography>Licence ID serial: {subData.licence_id}</Typography>
        </Stack>
      </Stack>
    </Card>
  );
};

export default SubscriptionDetail;
