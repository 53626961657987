import React from "react";
import { useSelector } from "react-redux";
import {
  Alert,
  AlertTitle,
  Button,
  Card,
  CardContent,
  Divider,
  Link,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  SvgIcon,
  Typography,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import {
  AndroidRounded,
  MenuBookTwoTone,
  SupportAgentOutlined,
  SupportRounded,
} from "@mui/icons-material";
import { mdiCogs } from "@mdi/js";
import { app_version } from "../../config/site";

const Welcome = () => {
  const { userSession } = useSelector((store) => store.signin);
  const { partner_name } = useSelector((store) => store.user);
  const navigate = useNavigate();

  return (
    <Card elevation={4} sx={{ width: "98%", height: "auto" }}>
      <CardContent>
        <Typography variant="h4" sx={{ mx: "2px", p: "1px" }}>
          Welcome to the Copperhead Partner Portal!
        </Typography>
        <Typography variant="caption" sx={{ mx: 5 }}>
          Version {app_version}
        </Typography>
        <Divider variant="middle" sx={{ p: "1px" }} />
        <Alert severity="success">
          <AlertTitle>CopperheadOS 13.09.2 is now available!</AlertTitle>
          <Typography variant="caption">SUPPORTED PIXEL DEVICES: 4, 4 XL, 4A, 4A 5G, 5, 5A, 6, 6A, and 7</Typography>
        </Alert>
        {partner_name ? (
          <React.Fragment>
            <Typography variant="body1" sx={{ mx: "2px", p: "1px" }}>
              Hello, team {partner_name}, we're excited to have you back!
              <br />
              Click on the{" "}
              <Button
                onClick={() => {
                  navigate("/subscriptions/");
                }}
              >
                Licenses & Subscriptions
              </Button>{" "}
              tab to view active licenses, QR codes or to view expiry dates.
            </Typography>
            <List dense disablePadding>
              <ListItem key={1}>
                <Link href="https://copperhead.co/android/docs/updates/#copperheados-stable-releases">
                  <ListItemButton>
                    <ListItemIcon>
                      <SvgIcon>
                        <path d={mdiCogs} />
                      </SvgIcon>
                    </ListItemIcon>
                    <ListItemText
                      primary="CopperheadOS Latest Information"
                      secondary="Review our latest OS releases on the main updates page."
                    />
                  </ListItemButton>
                </Link>
              </ListItem>
              <ListItem key={2}>
                <Link href="https://copperhead.co/android/docs/devices/#support-status">
                  <ListItemButton>
                    <ListItemIcon>
                      <AndroidRounded />
                    </ListItemIcon>
                    <ListItemText
                      primary="CopperheadOS Supported Devices"
                      secondary="Review CopperheadOS Pixel device support status from official documentation."
                    />
                  </ListItemButton>
                </Link>
              </ListItem>
              <ListItem key={3}>
                <Link href="https://copperhead.freshdesk.com/support/home">
                  <ListItemButton>
                    <ListItemIcon>
                      <SupportAgentOutlined />
                    </ListItemIcon>
                    <ListItemText
                      primary="Partner Support"
                      secondary="Copperhead has a fully-staffed support desk ready to assist our partners. All partners need to signup to the support desk to submit tickets or review solutions."
                    />
                  </ListItemButton>
                </Link>
              </ListItem>
            </List>
            <List dense disablePadding>
              <Typography variant="h6">Partner Solutions</Typography>
              <ListItem key={1}>
                <Link href="https://copperhead.freshdesk.com/support/solutions/articles/14000137737-copperhead-partner-portal#activatelicense">
                  <ListItemButton>
                    <ListItemIcon>
                      <MenuBookTwoTone />
                    </ListItemIcon>
                    <ListItemText primary="Activating a new license" />
                  </ListItemButton>
                </Link>
              </ListItem>
              <ListItem key={2}>
                <Link href="https://copperhead.freshdesk.com/support/solutions/articles/14000137737-copperhead-partner-portal#extendlicense">
                  <ListItemButton>
                    <ListItemIcon>
                      <MenuBookTwoTone />
                    </ListItemIcon>
                    <ListItemText primary="Extending an existing license." />
                  </ListItemButton>
                </Link>
              </ListItem>
              <Typography variant="h6">Common Questions</Typography>
              <ListItem key={3}>
                <Link href="https://copperhead.freshdesk.com/support/solutions/articles/14000129041-copperheados-licences#accounttag">
                  <ListItemButton>
                    <ListItemIcon>
                      <SupportRounded />
                    </ListItemIcon>
                    <ListItemText primary="How do account tags work?" />
                  </ListItemButton>
                </Link>
              </ListItem>
            </List>
          </React.Fragment>
        ) : (
          <Typography variant="body1" sx={{ mx: "2px", p: "1px" }}>
            Hello! No partner data was found for the user:{" "}
            {userSession.username}. Not to worry! Your sign-up is being
            processed, and your account will be ready to use soon.
          </Typography>
        )}
      </CardContent>
    </Card>
  );
};

export default Welcome;
