import { configureStore, createListenerMiddleware } from "@reduxjs/toolkit";
import { Auth } from "aws-amplify";
import { ENV_AUTH_PROVIDER, ENV_CLIENTID } from "../config/site";
import signinReducer, { signinActions, awsLogout } from "./auth/signinSlice";
import userReducer, { getPartnerData } from "./user/userSlice";
import uiReducer from "./UI/uiSlice";
import adminReducer, {
  adminActions,
  getAdminData,
  getAdminProds,
} from "./admin/adminSlice";
import creditReducer, {
  creditActions,
  getCreditData,
} from "./credits/creditsSlice";
import notifierReducer from "./notifications/reducers";
import subsReducer, {
  getSubscriptionData,
} from "./subscriptions/subscriptionsSlice";

const calculateRemainingTime = (expirationTime) => {
  const currentTime = new Date().getTime();
  const adjExpirationTime = expirationTime * 1000;
  const remainingDuration = adjExpirationTime - currentTime;
  return remainingDuration;
};

const getTokenExpiration = async () => {
  const session = await Auth.currentSession();
  if (session) {
    const expirationTime = session.getAccessToken().getExpiration();
    const remainingTime = calculateRemainingTime(expirationTime);
    const userEmail = localStorage.getItem(
      ENV_AUTH_PROVIDER + "." + ENV_CLIENTID + ".LastAuthUser"
    );
    localStorage.setItem(
      ENV_AUTH_PROVIDER +
      "." +
      ENV_CLIENTID +
      "." +
      userEmail +
      ".expirationTime",
      expirationTime
    );
    return remainingTime;
  }
};

const signinMiddleware = createListenerMiddleware();

signinMiddleware.startListening({
  actionCreator: signinActions.setCognitoUser,
  effect: async (action, listenerApi) => {
    listenerApi.cancelActiveListeners();

    let tokenExpiry;

    if (!action.payload.sessionExpiry) {
      tokenExpiry = await getTokenExpiration();
    } else {
      const time = calculateRemainingTime(action.payload.sessionExpiry);
      tokenExpiry = time;
    }

    const userTask = listenerApi.fork(async (forkApi) => {
      await forkApi.delay(375);
      return listenerApi.dispatch(getPartnerData());
    });
    const userResult = await userTask.result;
    if (userResult.status === "ok") {
      const thisState = listenerApi.getState();
      const creditTask = listenerApi.fork(async (forkApi) => {
        await forkApi.delay(750);
        return listenerApi.dispatch(getCreditData());
      });
      const creditResult = await creditTask.result;
      if (creditResult.status === "ok") {
        const creatorsTask = listenerApi.fork(async () => {
          return listenerApi.dispatch(creditActions.sortByType());
        });
        const creatorsResult = await creatorsTask.result;
        if (creatorsResult.status !== "ok") {
          console.info(
            "Creator array could not be created from credit listing"
          );
        }
      }

      const subsTask = listenerApi.fork(async (forkApi) => {
        await forkApi.delay(100);
        return listenerApi.dispatch(getSubscriptionData());
      });
      const subsResult = await subsTask.result;
      if (subsResult.status !== "ok") {
        console.info("Subs GET task failed.");
      }

      if (thisState.user.show_credits_admin) {
        const adminDataTask = listenerApi.fork(async (forkApi) => {
          await forkApi.delay(1000);
          return listenerApi.dispatch(getAdminData());
        });
        const adminProdsTask = listenerApi.fork(async (forkApi) => {
          await forkApi.delay(1100);
          return listenerApi.dispatch(getAdminProds());
        });
        const adminDataResult = await adminDataTask.result;
        if (adminDataResult.status !== "ok") {
          console.info("Admin primary GET task ran and failed.");
        }
        const adminProdsResult = await adminProdsTask.result;
        if (adminProdsResult.status !== "ok") {
          console.info("Admin secondary GET task ran and failed.");
        }
      }
    }

    const logoutTask = listenerApi.fork(async (forkApi) => {
      await forkApi.delay(tokenExpiry - 6000);

      return listenerApi.dispatch(awsLogout()).unwrap();
    });

    const logoutResult = await logoutTask.result;
    if (logoutResult.status !== "ok") {
      console.info("Session has expired, user is logged out.");
    }
  },
});

const adminSortMiddleware = createListenerMiddleware();
adminSortMiddleware.startListening({
  actionCreator: getAdminProds.fulfilled,
  effect: async (action, listenerApi) => {
    listenerApi.cancelActiveListeners();

    listenerApi.dispatch(adminActions.sortByCreditType());
  },
});

const updateAdminStateMachine = createListenerMiddleware();
updateAdminStateMachine.startListening({
  actionCreator: adminActions.setUpdateAdmin,
  effect: async (action, listenerApi) => {
    listenerApi.cancelActiveListeners();

    listenerApi.fork(async (forkApi) => {
      return listenerApi.dispatch(getAdminData());
    });

    listenerApi.fork(async (forkApi) => {
      return listenerApi.dispatch(getAdminProds());
    });
  },
});

const updateUserMiddleware = createListenerMiddleware();
updateUserMiddleware.startListening({
  actionCreator: creditActions.spendOne,
  effect: async (action, listenerApi) => {
    listenerApi.cancelActiveListeners();
    console.log("Refreshing user data: ", action.type);
    const refetchSubs = listenerApi.fork(async (forkApi) => {
      await forkApi.delay(550);

      return listenerApi.dispatch(getSubscriptionData());
    });

    const subsUpdate = await refetchSubs.result;
    if (subsUpdate.status !== "ok") {
      console.log(subsUpdate, subsUpdate.status);
    }

    const refetchCredits = listenerApi.fork(async (forkApi) => {
      await forkApi.delay(830);

      return listenerApi.dispatch(getCreditData());
    });

    const creditsUpdate = await refetchCredits.result;
    if (creditsUpdate.status === "ok") {
      listenerApi.dispatch(creditActions.sortByType());
    }
  },
});

export const store = configureStore({
  reducer: {
    signin: signinReducer,
    user: userReducer,
    ui: uiReducer,
    credits: creditReducer,
    subs: subsReducer,
    admin: adminReducer,
    notifier: notifierReducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().prepend(
      signinMiddleware.middleware,
      updateUserMiddleware.middleware,
      updateAdminStateMachine.middleware,
      adminSortMiddleware.middleware
    ),
});
