import { AddBusiness, CloseSharp } from "@mui/icons-material";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContentText,
  DialogTitle,
  MenuItem,
  TextField,
} from "@mui/material";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  adminActions,
  createDownstreamBusiness,
} from "../../store/admin/adminSlice";
import { enqueueSnackbar } from "../../store/notifications/actions";

const AddPartnerBusiness = () => {
  const [value, setValue] = React.useState(0);
  const businessRef = React.useRef();
  const selectRef = React.useRef();
  const { showAddPartnerDialog, adminPartners } = useSelector(
    (store) => store.admin
  );
  const dispatch = useDispatch();

  const handleClose = (event) => {
    event.preventDefault();

    dispatch(adminActions.handleAddBusinessDialog());
  };

  const handleChange = (event) => {
    setValue(event.target.value);
  };

  const handleSubmission = async (event) => {
    event.preventDefault();
    const businessName = businessRef.current.value.trim();
    console.log("Business to be submitted: ", businessName);

    if (businessName.length < 3) {
      dispatch(
        enqueueSnackbar({
          message:
            "Business names should be more than just 3 alphanumeric characters.",
          options: {
            variant: "warning",
            autoHideDuration: 12000,
            resumeHideDuration: 800,
          },
        })
      );

      return;
    }

    const parentId = selectRef.current.value;

    if (parentId === 0) {
      dispatch(
        enqueueSnackbar({
          message:
            "You must select a Parent Business to add this partner under.",
          options: {
            variant: "warning",
            autoHideDuration: 12000,
            resumeHideDuration: 800,
          },
        })
      );

      return;
    }

    const template = `{ "parent_id": ${parentId}, "new_partner_name": "${businessName}"}`;
    const sendable = JSON.parse(template);

    dispatch(createDownstreamBusiness(sendable));
    dispatch(adminActions.handleAddBusinessDialog());
  };

  return (
    <Dialog
      disableEnforceFocus
      disablePortal
      open={showAddPartnerDialog}
      onClose={handleClose}
      aria-labelledby="dialog-partner-business-add"
      aria-describedby="dialog-partner-business-add"
      sx={{
        justifySelf: "center",
        justifyContent: "center",
        alignSelf: "center",
        alignContent: "center",
        alignItems: "center",
        display: "flex",
      }}
    >
      <DialogTitle sx={{ p: 1, mb: 1 }}>Add a New Partner Business</DialogTitle>
      <DialogContentText sx={{ p: 1 }}>
        This will create a new partner business under the target partner
        business.
      </DialogContentText>
      <TextField
        sx={{ p: 1 }}
        label="New Partner Business name"
        type="text"
        inputRef={businessRef}
      ></TextField>
      <TextField
        sx={{ p: 1 }}
        label="Target Parent Business"
        select
        helperText="The new partner business must be created subordinate to a non-Copperhead admin user."
        value={value}
        onChange={handleChange}
        inputRef={selectRef}
      >
        <MenuItem value={0}>
          Select a Parent Business to add the New Partner Business under.
        </MenuItem>
        {adminPartners.map((partner) => (
          <MenuItem key={partner.id} value={partner.id}>
            {partner.id}, {partner.business_name}
          </MenuItem>
        ))}
      </TextField>
      <DialogActions sx={{ justifyContent: "space-between" }}>
        <Button
          variant="contained"
          endIcon={<CloseSharp />}
          onClick={handleClose}
        >
          Cancel
        </Button>
        <Button
          variant="contained"
          startIcon={<AddBusiness />}
          onClick={handleSubmission}
        >
          Submit
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default AddPartnerBusiness;
