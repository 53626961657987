import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Outlet, useNavigate } from "react-router-dom";
import useThemeSelector from "./lib/useThemeSelector";
import { ThemeProvider, useTheme } from "@mui/material/styles";
import {
  Box,
  Container,
  CssBaseline,
  Divider,
  IconButton,
  Paper,
  Stack,
  useMediaQuery,
} from "@mui/material";
import SignIn from "./store/auth/SignIn";
import { useAuthenticator } from "@aws-amplify/ui-react";
import { signinActions } from "./store/auth/signinSlice";
import AdminStatus from "./lib/AdminStatus";
import UserStatus from "./lib/UserStatus";
import TopBar from "./components/Layout/TopBar";
import NavBar from "./components/Layout/NavBar";
import Footer from "./components/Layout/Footer";
import MobileBar from "./components/Layout/MobileBar";
import { uiActions } from "./store/UI/uiSlice";
import { ArrowLeft, ArrowRight } from "@mui/icons-material";

function AppLayout() {
  const { showNav } = useSelector((store) => store.ui);
  const navigate = useNavigate();
  const theme = useThemeSelector();
  const muiTheme = useTheme();
  const xsScreen = useMediaQuery(muiTheme.breakpoints.up("xs"));
  const smScreen = useMediaQuery(muiTheme.breakpoints.up("sm"));
  const mdScreen = useMediaQuery(muiTheme.breakpoints.up("md"));
  const lgScreen = useMediaQuery(muiTheme.breakpoints.up("lg"));
  const xlScreen = useMediaQuery(muiTheme.breakpoints.up("xl"));
  const dispatch = useDispatch();

  React.useLayoutEffect(() => {
    if (xsScreen || smScreen) {
      dispatch(uiActions.hideNavBar());
    }

    if (mdScreen || lgScreen || xlScreen) {
      dispatch(uiActions.showNavBar());
    }
  }, [dispatch, xsScreen, smScreen, mdScreen, lgScreen, xlScreen]);

  if (mdScreen || lgScreen || xlScreen) {
    return (
      <ThemeProvider theme={theme}>
        <CssBaseline enableColorScheme />
        <UserStatus />
        <AdminStatus />
        <Container maxWidth="xl" sx={{ minHeight: "100vh" }}>
          {showNav ? (
            <Box
              sx={{
                minHeight: "100vh",
                display: "grid",
                gridTemplateColumns: "repeat(6, 1fr)",
                gap: 1,
                gridTemplateRows: "auto",
                gridTemplateAreas: `"header  header header header header header "
                                "sidebar main   main   main   main   main"
                                "footer  footer footer footer footer footer "`,
              }}
            >
              <TopBar />
              <NavBar />
              <Box sx={{ gridArea: "main" }}>
                <Paper sx={{ minHeight: "85.1vh" }}>

                  <Stack
                    sx={{ justifyContent: "space-between" }}
                    direction="row"
                  >
                    <IconButton
                      aria-label="back-button"
                      size="medium"
                      onClick={() => {
                        navigate(-1);
                      }}
                    >
                      <ArrowLeft />
                    </IconButton>
                    <IconButton
                      aria-label="forward-button"
                      size="medium"
                      onClick={() => {
                        navigate(1);
                      }}
                    >
                      <ArrowRight />
                    </IconButton>
                  </Stack>

                  <Outlet />
                </Paper>
              </Box>
              <Box sx={{ gridArea: "footer", bgcolor: "primary.main" }}>
                <Footer />
              </Box>
            </Box>
          ) : (
            <Box
              sx={{
                minHeight: "100vh",
                display: "grid",
                gridTemplateColumns: "repeat(6, 1fr)",
                gap: 1,
                gridTemplateRows: "auto",
                gridTemplateAreas: `"header  header header header header header "
                                  "main main   main   main   main   main"
                                  "footer  footer footer footer footer footer "`,
              }}
            >
              <TopBar />
              <Box sx={{ gridArea: "main" }}>
                <Paper sx={{ minHeight: "85.1vh" }}>

                  <Stack
                    sx={{ justifyContent: "space-between" }}
                    direction="row"
                  >
                    <IconButton
                      aria-label="back-button"
                      size="small"
                      onClick={() => {
                        navigate(-1);
                      }}
                    >
                      <ArrowLeft />
                    </IconButton>
                    <IconButton
                      aria-label="forward-button"
                      size="small"
                      onClick={() => {
                        navigate(1);
                      }}
                    >
                      <ArrowRight />
                    </IconButton>
                  </Stack>

                  <Outlet />
                </Paper>
              </Box>
              <Box sx={{ gridArea: "footer", bgcolor: "primary.main" }}>
                <Footer />
              </Box>
            </Box>
          )}
        </Container>
      </ThemeProvider>
    );
  }

  if (xsScreen || smScreen) {
    return (
      <ThemeProvider theme={theme}>
        <CssBaseline enableColorScheme />
        <UserStatus />
        <AdminStatus />
        <Container maxWidth="sm" sx={{ minHeight: "100vh" }}>
          <Box
            sx={{
              minHeight: "100vh",
              display: "grid",
              gridTemplateColumns: "repeat(6, 1fr)",
              gap: 1,
              gridTemplateRows: "auto",
              gridTemplateAreas: `"header  header header header header header "
                                "main main   main   main   main   main"
                                "footer  footer footer footer footer footer "`,
            }}
          >
            <MobileBar />
            <Box sx={{ gridArea: "main" }}>
              <Paper sx={{ minHeight: "85.1vh" }}>
                <Divider />
                <Stack sx={{ justifyContent: "space-between" }} direction="row">
                  <IconButton
                    aria-label="back-button"
                    size="large"
                    onClick={() => {
                      navigate(-1);
                    }}
                  >
                    <ArrowLeft />
                  </IconButton>
                  <IconButton
                    aria-label="forward-button"
                    size="large"
                    onClick={() => {
                      navigate(1);
                    }}
                  >
                    <ArrowRight />
                  </IconButton>
                </Stack>
                <Divider />
                <Outlet />
              </Paper>
            </Box>
            <Box sx={{ gridArea: "footer", bgcolor: "primary.main" }}>
              <Footer />
            </Box>
          </Box>
        </Container>
      </ThemeProvider>
    );
  }
}

function App() {
  const { route } = useAuthenticator((context) => [context.route]);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  React.useEffect(() => {
    if (route === "authenticated") {
      dispatch(signinActions.login());
    } else if (route === "signOut") {
      navigate("/", { replace: true });
    } else {
      return () => {};
    }
  }, [navigate, dispatch, route]);

  return (
    <React.Fragment>
      {route === "authenticated" ? <AppLayout /> : <SignIn />}
    </React.Fragment>
  );
}

export default App;
