import {
  mdiCheckCircle,
  mdiExclamationThick,
  mdiProgressQuestion,
} from "@mdi/js";
import { Close } from "@mui/icons-material";
import {
  ListItemAvatar,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  MenuItem,
  MenuList,
  SvgIcon,
} from "@mui/material";
import React from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { dismissHistory, toggleNotifierMenu } from "./actions";

const LastTenAlerts = () => {
  const { lastTen } = useSelector((store) => store.notifier);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  return (
    <MenuList>
      {lastTen.length > 0 &&
        lastTen.map((notification) => {
          return (
            <MenuItem key={notification.key}>
              <ListItemAvatar>
                <SvgIcon>
                  {notification.options.variant === "success" && (
                    <path d={mdiCheckCircle} />
                  )}
                  {notification.options.variant === "warning" && (
                    <path d={mdiProgressQuestion} />
                  )}
                  {notification.options.variant === "error" && (
                    <path d={mdiExclamationThick} />
                  )}
                </SvgIcon>
              </ListItemAvatar>
              <ListItemText primary={notification.message} />
              <ListItemButton
                onClick={() => {
                  dispatch(dismissHistory(notification.key));
                }}
              >
                <ListItemIcon>
                  <Close />
                </ListItemIcon>
              </ListItemButton>
            </MenuItem>
          );
        })}
      <MenuItem
        onClick={() => {
          navigate("/");
          dispatch(toggleNotifierMenu());
        }}
      >
        <ListItemAvatar>
          <SvgIcon>
            <path d={mdiCheckCircle} />
          </SvgIcon>
        </ListItemAvatar>
        <ListItemText
          primary="Welcome!"
          secondary="Check out the 'Home' page for news and support links."
        />
      </MenuItem>
      {lastTen.length > 0 && (
        <MenuItem
          onClick={() => {
            dispatch(dismissHistory());
          }}
          sx={{ justifyContent: "center" }}
        >
          <ListItemText primary="Dismiss All">
            <ListItemIcon>
              <Close />
            </ListItemIcon>
          </ListItemText>
        </MenuItem>
      )}
    </MenuList>
  );
};

export default LastTenAlerts;
