const awsmobile = {
  "aws_project_region": process.env.REACT_APP_AWS_REGION,
  "aws_cognito_identity_pool_id": process.env.REACT_APP_AWS_IDPOOLID,
  "aws_cognito_region": process.env.REACT_APP_AWS_REGION,
  "aws_user_pools_id": process.env.REACT_APP_AWS_USERPOOLID,
  "aws_user_pools_web_client_id": process.env.REACT_APP_AUTH_CLIENTID,
  "oauth": {
      "domain": process.env.REACT_APP_AWS_OAUTH_DOMAIN,
      "scope": [
          "phone",
          "email",
          "openid",
          "profile",
          "aws.cognito.signin.user.admin"
      ],
      "redirectSignIn": process.env.REACT_APP_AWS_URL,
      "redirectSignOut": process.env.REACT_APP_AWS_URL,
      "responseType": "code"
  },
  "federationTarget": "COGNITO_USER_POOLS",
  "aws_cognito_username_attributes": [],
  "aws_cognito_social_providers": [],
  "aws_cognito_signup_attributes": [
      "EMAIL",
      "NAME"
  ],
  "aws_cognito_mfa_configuration": "OPTIONAL",
  "aws_cognito_mfa_types": [
      "TOTP"
  ],
  "aws_cognito_password_protection_settings": {
      "passwordPolicyMinLength": "16",
      "passwordPolicyCharacters": [
          "REQUIRES_LOWERCASE",
          "REQUIRES_UPPERCASE",
          "REQUIRES_NUMBERS",
          "REQUIRES_SYMBOLS"
      ]
  },
  "aws_cognito_verification_mechanisms": [
      "EMAIL"
  ],
  "cookie_storage": {
      "domain": process.env.REACT_APP_AWS_URL,
      "path": "/",
      "expires": "1",
      "secure": "true"
  }
};


export default awsmobile;