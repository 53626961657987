import { Close, RemoveModerator } from "@mui/icons-material";
import {
  Button,
  Card,
  CardActions,
  Divider,
  FormControl,
  MenuItem,
  TextField,
  Typography,
} from "@mui/material";
import { Stack } from "@mui/system";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import ErrorBoundary from "../../lib/classes/ErrorBoundary";
import { adminActions, removeCredits } from "../../store/admin/adminSlice";
import { enqueueSnackbar } from "../../store/notifications/actions";
import CopperheadFallback from "../custom/CopperheadFallback";

const RemoveCredits = (props) => {
  const deferred = React.useDeferredValue(props);
  const [cvalue, setValue] = React.useState("");
  const [disable, setDisable] = React.useState(true);
  const productRef = React.useRef("");
  const qtyRef = React.useRef("");
  const dispatch = useDispatch();
  const {
    adminLoading,
    adminProdData,
    adminCreateProducts,
    adminRenewProducts,
  } = useSelector((store) => store.admin);
  const curIndex = adminProdData.findIndex(
    (element) => element.kind === deferred.kind
  );
  const [product, setProduct] = React.useState(
    +adminProdData[curIndex]?.kind || +adminProdData[0].kind
  );

  const handleProduct = (event) => {
    setProduct(event.target.value);
  };

  const handleQuantity = (event) => {
    if (event.target.value >= 0) {
      setValue(event.target.value);
    }
    if (event.target.value > 0) {
      setDisable(false);
    }
  };

  const handleBlur = (event) => {
    if (!qtyRef.current.value) {
      setDisable(true);
    }

    if (qtyRef.current.value > 0) {
      setDisable(false);
    }
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    if (qtyRef.current.value <= 0) {
      dispatch(
        enqueueSnackbar({
          message: "You probably want to remove more than zero (0) credits.",
          options: {
            variant: "warning",
            autoHideDuration: 12000,
            resumeHideDuration: 800,
          },
        })
      );

      return;
    }

    if (qtyRef.current.value > deferred.maxQty) {
      dispatch(
        enqueueSnackbar({
          message:
            "You cannot remove more credits than are currently available.",
          options: {
            variant: "warning",
            autoHideDuration: 12000,
            resumeHideDuration: 800,
          },
        })
      );

      return;
    }

    const prodInput = productRef.current.value;
    const qtyInput = qtyRef.current.value;
    const mangle = `{ "partner_id": ${+deferred.partner_id}, "kind": ${+prodInput}, "qty": ${+qtyInput} }`;
    const realJson = JSON.parse(mangle);

    dispatch(removeCredits(realJson));
    dispatch(adminActions.setUpdateAdmin());
    dispatch(adminActions.handleRemoveDialog());
  };

  const handleClose = (event) => {
    event.preventDefault();

    dispatch(adminActions.handleRemoveDialog());
  };

  if (adminLoading) {
    return <CopperheadFallback />;
  }

  return (
    <ErrorBoundary>
      <Card
        sx={{ display: "inline-grid", p: 1, justifyContent: "space-between" }}
        id="dialog-credits-remove"
      >
        <Typography variant="h5">
          Partner: {deferred.business_name}, #{deferred.partner_id}{" "}
        </Typography>
        <Divider />
        <FormControl>
          <TextField
            id="outlined-select-product"
            select
            label="Product"
            value={product}
            onChange={handleProduct}
            inputRef={productRef}
            helperText="Select a product package."
            sx={{ my: 2 }}
          >
            <Stack direction="column" sx={{ justifyContent: "center" }}>
              <Divider variant="middle"></Divider>
              <Typography variant="caption" sx={{ mx: "3px" }}>
                Create New Products:
              </Typography>
              <Divider variant="middle"></Divider>
            </Stack>
            {adminCreateProducts.map((creator) => (
              <MenuItem key={creator.kind} value={creator.kind}>
                {creator.description}
              </MenuItem>
            ))}
            <Stack direction="column" sx={{ justifyContent: "center" }}>
              <Divider variant="middle"></Divider>
              <Typography variant="caption" sx={{ mx: "3px" }}>
                Renewal Products:
              </Typography>
              <Divider variant="middle"></Divider>
            </Stack>
            {adminRenewProducts.map((renewal) => (
              <MenuItem key={renewal.kind} value={renewal.kind}>
                {renewal.description}
              </MenuItem>
            ))}
            <Divider variant="middle"></Divider>
          </TextField>

          <TextField
            value={cvalue}
            type="number"
            inputProps={{ inputMode: "numeric", pattern: "[0-9]*" }}
            id="filled-credit-quant"
            label="Credits to remove..."
            onChange={handleQuantity}
            onBlur={handleBlur}
            inputRef={qtyRef}
            helperText="Select the number of credits to remove."
            sx={{ my: 2 }}
            variant="filled"
          />
          <Divider />
          <CardActions sx={{ justifyContent: "space-between" }}>
            <Button
              endIcon={<Close />}
              color="warning"
              variant="contained"
              sx={{ p: 1 }}
              onClick={handleClose}
            >
              Cancel
            </Button>
            <Button
              disabled={disable}
              startIcon={<RemoveModerator />}
              variant="contained"
              sx={{ p: 1 }}
              color="error"
              onClick={handleSubmit}
            >
              Confirm
            </Button>
          </CardActions>
        </FormControl>
      </Card>
    </ErrorBoundary>
  );
};

export default RemoveCredits;
