/**
 * Retrieves the license header string from a full licenseID and assures formatting.
 * @param {string} licenseId: the probable Copperhead license ID string to be checked.
 * @returns {string}
*/
function checkedGet(licenseId) {
  const licenseHeader = licenseId.substring(0, 8).trim();
  if (!/^[0-9a-fA-F]+$/.test(licenseHeader)) {
    throw new Error("licenceId header contains invalid characters");
  } else if (licenseHeader.length !== 8) {
    throw new Error("licenceId header is sized incorrectly");
  } else {
    return licenseHeader;
  }
}
/**
 * Determines the product associated with the license ID, based on the currently
 * known header values.
 * @param {string} licenseId: A probable Copperhead license ID string.
 * @returns {{product_name: {string}}} product_name: {string}
*/
function fromHeader(licenseId) {
  switch (checkedGet(licenseId)) {
    case "022e0901":
      return { product_name: "CopperheadOS" };
    case "01d16524":
      return { product_name: "Kontact" };
    case "01d16520":
      return { product_name: "CryptoLock" };
    case "01d1651a":
      return { product_name: "CryptoWork" };
    case "01d1651c":
      return { product_name: "CryptoLite" };
    case "01d164a1":
      return { product_name: "QA Rebrand" };
    case "01c78f43":
      return { product_name: "Dark OS" };
    case "01c82b07":
      return { product_name: "Wolflock" };
    case "01c82a9d":
      return { product_name: "Trust In Electronics" };
    case "01c8295b":
      return { product_name: "Silent Secret" };
    case "01c82aa5":
      return { product_name: "King Cow Finance" };
    case "01c8285e":
      return { product_name: "Visser"};
    case "01c82aee":
      return { product_name: "Forever" };
    case "01c829bd":
      return { product_name: "PhoneCasa" };
    default:
      return { product_name: "New or Unknown" };
  }
}

export default fromHeader;
