import { createSlice } from "@reduxjs/toolkit";
const paletteMode = localStorage.getItem("paletteMode");
const layoutMode = localStorage.getItem("layoutMode");

const uiSlice = createSlice({
  name: "ui",
  initialState: {
    showNav: true,
    creditsLayout: layoutMode || "grid",
    paletteMode: paletteMode || "dark",
    activeProduct: 0,
  },
  reducers: {
    togglePaletteMode: {
      reducer: (state, action) => {
        if (action.payload === "light" || action.payload === "dark") {
          state.paletteMode = action.payload;
          localStorage.setItem("paletteMode", action.payload);
        }
      },
    },
    toggleNavBar: {
      reducer: (state) => {
        state.showNav = !state.showNav;
      },
    },
    hideNavBar: {
      reducer: (state) => {
        state.showNav = false;
      },
    },
    showNavBar: {
      reducer: (state) => {
        state.showNav = true;
      },
    },
    setActiveProduct: {
      reducer: (state, action) => {
        state.activeProduct = action.payload;
      },
    },
    stepForward: {
      reducer: (state) => {
        state.activeProduct = state.activeProduct + 1;
      },
    },
    stepBack: {
      reducer: (state) => {
        if (state.activeProduct >= 0) {
          state.activeProduct = state.activeProduct - 1;
        }
      },
    },
    setLayout: {
      reducer: (state, action) => {
        state.creditsLayout = action.payload;
        localStorage.setItem("layoutMode", action.payload);
      },
    },
  },
});

export const uiActions = uiSlice.actions;

export default uiSlice.reducer;
