import React from "react";
import { useSelector } from "react-redux";
import { Button, Card, CardHeader, Stack, useMediaQuery } from "@mui/material";
import {
  ArrowForward,
  FileDownloadOutlined,
  LockOpenOutlined,
  QrCode2,
} from "@mui/icons-material";
import ExtendDialog from "./ExtendDialog";
import { useTheme } from "@mui/styles";
import ReEnrollDialog from "./ReEnrollDialog";

const SubToolbar = (props) => {
  const { creditListing } = useSelector((store) => store.credits);
  const { subscriptionId, subData, qrError, qrLoading, url } =
    React.useDeferredValue(props);
  const { setUpdateSubInfo, handleOpen, handleReEnrollDialog } = props;
  const theme = useTheme();
  const xsScreen = useMediaQuery(theme.breakpoints.up("xs"));
  const smScreen = useMediaQuery(theme.breakpoints.up("sm"));
  const mdScreen = useMediaQuery(theme.breakpoints.up("md"));
  const lgScreen = useMediaQuery(theme.breakpoints.up("lg"));
  const xlScreen = useMediaQuery(theme.breakpoints.up("xl"));

  if (mdScreen || lgScreen || xlScreen) {
    return (
      <Card variant="outlined" sx={{ height: "13rem", my: "3px" }}>
        <CardHeader avatar={<QrCode2 />} title="Licence Actions" />
        {creditListing.length > 0 && <ExtendDialog
          subscriptionId={+subscriptionId}
          productKind={subData.product_name}
          identifier={subData.identifier}
          licenceId={subData.licence_id}
          update={setUpdateSubInfo}
        />}
        <ReEnrollDialog
          subscriptionId={+subscriptionId}
          identifier={subData.identifier}
        />
        <Stack direction="column">
          {creditListing.length > 0 && (
            <Button
              sx={{ width: "11rem", height: "3rem", my: "1px" }}
              startIcon={<ArrowForward />}
              variant="contained"
              onClick={handleOpen}
            >
              Renew
            </Button>
          )}
          <Button
            variant="text"
            sx={{ width: "11rem", height: "3rem", my: "1px" }}
            startIcon={<LockOpenOutlined />}
            onClick={handleReEnrollDialog}
          >
            Re-enroll
          </Button>
          {!qrError || !qrLoading ? (
            <a href={url} download={`${subData.identifier}.png`}>
              <Button
                startIcon={<FileDownloadOutlined />}
                sx={{ width: "11rem", height: "3rem", my: "1px" }}
                variant="text"
              >
                Download
              </Button>
            </a>
          ) : (
            <Button
              disabled={true}
              startIcon={<FileDownloadOutlined />}
              variant="text"
              sx={{ width: "11rem", height: "3rem", my: "1px" }}
            >
              Download
            </Button>
          )}
        </Stack>
      </Card>
    );
  }

  if (xsScreen || smScreen) {
    return (
      <Card
        variant="outlined"
        sx={{ height: "3.2rem", width: "auto", my: "3px" }}
      >
        {creditListing.length > 0 && <ExtendDialog
          subscriptionId={+subscriptionId}
          productKind={subData.product_name}
          identifier={subData.identifier}
          licenceId={subData.licence_id}
          update={setUpdateSubInfo}
        />}
        <ReEnrollDialog
          subscriptionId={+subscriptionId}
          identifier={subData.identifier}
        />
        <Stack direction="row" sx={{ justifyContent: "space-between" }}>
          {creditListing.length > 0 && (
            <Button
              sx={{ width: "auto", height: "3rem", mr: 1 }}
              startIcon={<ArrowForward />}
              variant="contained"
              onClick={handleOpen}
            >
              Renew
            </Button>
          )}
          <Button
            variant="text"
            sx={{ width: "auto", height: "3rem", mx: 1 }}
            startIcon={<LockOpenOutlined />}
            onClick={handleReEnrollDialog}
          >
            Re-enroll...
          </Button>
          {!qrError || !qrLoading ? (
            <a href={url} download={`${subData.identifier}.png`}>
              <Button
                startIcon={<FileDownloadOutlined />}
                sx={{ width: "auto", height: "3rem", ml: 1 }}
                variant="text"
              >
                Download
              </Button>
            </a>
          ) : (
            <Button
              disabled={true}
              startIcon={<FileDownloadOutlined />}
              variant="text"
              sx={{ width: "auto", height: "3rem", ml: 1 }}
            >
              Download
            </Button>
          )}
        </Stack>
      </Card>
    );
  }
};

export default SubToolbar;
