import { RemoveCircle } from '@mui/icons-material'
import { IconButton, Tooltip } from '@mui/material'
import React from 'react'

const RemoveCreditsButton = (props) => {
  const { params, handleRemoveOpen } = React.useDeferredValue(props);
  const [qtyZero, setQtyZero] = React.useState(false);

  React.useLayoutEffect(() => {
    if (params.row.qty === 0) {
      setQtyZero(true);
    }

    if (params.row.qty >= 1) {
      setQtyZero(false);
    }

  }, [params, params.row.kind, params.row.qty])

  const handleClick = () => {
    handleRemoveOpen(params.row.kind, params.row.qty);
  }

  return (
    <Tooltip title="Open a dialog to REMOVE credits for this product, or another selectable product.">
      <span>
        <IconButton
          disabled={qtyZero}
          onClick={handleClick}
          size="small"
          style={{ ml: 8 }}
          tabIndex={params.hasFocus ? 0 : -1}
        ><RemoveCircle /></IconButton>
      </span>
    </Tooltip>
  )
}

export default RemoveCreditsButton