import React from "react";
import ReactDOM from "react-dom/client";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Outlet,
} from "react-router-dom";

import { Amplify } from "aws-amplify";
import { Authenticator } from "@aws-amplify/ui-react";
import { Provider } from "react-redux";
import { store } from "./store/store";
import { SnackbarProvider } from "notistack";
import AmplifyBridge from "./store/auth/AmplifyBridge";
import Amplifier from "./store/auth/Amplifier";

import App from "./App";
import CopperheadFallback from "./components/custom/CopperheadFallback";
import CreditsMain from "./components/Credits/CreditsView";
import CreditsUse from "./components/Credits/CreditsUse";
import CreditAdminGrid from "./components/Distributors/CreditAdminGrid";
import PartnerCredits from "./components/Distributors/PartnerCredits";
import RequireDistrib from "./store/auth/RequireDistrib";
import RequireAuth from "./store/auth/RequireAuth";
import SubscriptionList from "./components/Subs/SubscriptionList";
import SubscriptionDetail from "./components/Subs/SubscriptionDetail";
import Welcome from "./components/Splash/Welcome";

import { Box, IconButton } from "@mui/material";
import { Close } from "@mui/icons-material";
import awsExports from "./aws-exports";
import MrRobot from "./static/img/mrrobot.jpg";
import "@fontsource/fira-sans";
import "@fontsource/big-shoulders-text";
import "./index.css";

Amplify.configure(awsExports);
new AmplifyBridge(store);

function CreditsLayout() {
  return (
    <Box>
      <Outlet />
    </Box>
  );
}
const containerSx = {
  alignContent: "center",
  justifyContent: "center",
  textAlign: "center",
  mt: "10vh",
};
const logoSx = { minHeight: "33vmax", minWidth: "33vmax" };
const notistackRef = React.createRef();
const onClickDismiss = (key) => () => {
  notistackRef.current.closeSnackbar(key);
};
const container = document.getElementById("root");
const root = ReactDOM.createRoot(container);
root.render(
  <Authenticator.Provider>
    <Amplifier />
    <Provider store={store}>
      <SnackbarProvider
        ref={notistackRef}
        maxSnack={4}
        anchorOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
        sx={{ width: "35vw", height: 70 }}
        action={(key) => (
          <IconButton onClick={onClickDismiss(key)}>
            <Close />
          </IconButton>
        )}
      >
        <Router>
          <Routes>
            <Route
              path="/*"
              element={
                <React.Suspense
                  fallback={
                    <CopperheadFallback
                      containerStyle={containerSx}
                      logoStyle={logoSx}
                    />
                  }
                >
                  <App />
                </React.Suspense>
              }
            >
              <Route
                index
                element={
                  <React.Suspense
                    fallback={
                      <CopperheadFallback
                        containerStyle={containerSx}
                        logoStyle={logoSx}
                      />
                    }
                  >
                    <Welcome />
                  </React.Suspense>
                }
              />
              <Route path="subscriptions">
                <Route
                  path=":subscriptionId"
                  element={
                    <React.Suspense
                      fallback={
                        <CopperheadFallback
                          containerStyle={containerSx}
                          logoStyle={logoSx}
                        />
                      }
                    >
                      <RequireAuth>
                        <SubscriptionDetail />
                      </RequireAuth>
                    </React.Suspense>
                  }
                />
                <Route
                  index
                  element={
                    <React.Suspense
                      fallback={
                        <CopperheadFallback
                          containerStyle={containerSx}
                          logoStyle={logoSx}
                        />
                      }
                    >
                      <RequireAuth>
                        <SubscriptionList />
                      </RequireAuth>
                    </React.Suspense>
                  }
                />
              </Route>

              <Route
                path="credits"
                element={
                  <React.Suspense
                    fallback={
                      <CopperheadFallback
                        containerStyle={containerSx}
                        logoStyle={logoSx}
                      />
                    }
                  >
                    <RequireAuth>
                      <CreditsLayout />
                    </RequireAuth>
                  </React.Suspense>
                }
              >
                <Route
                  index
                  element={
                    <React.Suspense
                      fallback={
                        <CopperheadFallback
                          containerStyle={containerSx}
                          logoStyle={logoSx}
                        />
                      }
                    >
                      <RequireAuth>
                        <CreditsMain />
                      </RequireAuth>
                    </React.Suspense>
                  }
                />
                <Route path="admin">
                  <Route
                    index
                    element={
                      <RequireDistrib>
                        <React.Suspense
                          fallback={
                            <CopperheadFallback
                              containerStyle={containerSx}
                              logoStyle={logoSx}
                            />
                          }
                        >
                          <CreditAdminGrid />
                        </React.Suspense>
                      </RequireDistrib>
                    }
                  />
                  <Route
                    path=":partnerId"
                    element={
                      <RequireDistrib>
                        <React.Suspense
                          fallback={
                            <CopperheadFallback
                              containerStyle={containerSx}
                              logoStyle={logoSx}
                            />
                          }
                        >
                          <PartnerCredits />
                        </React.Suspense>
                      </RequireDistrib>
                    }
                  />
                </Route>

                <Route
                  path="use"
                  element={
                    <React.Suspense
                      fallback={
                        <CopperheadFallback
                          containerStyle={containerSx}
                          logoStyle={logoSx}
                        />
                      }
                    >
                      <CreditsUse />
                    </React.Suspense>
                  }
                />
              </Route>
            </Route>
            <Route
              path="*"
              element={
                <main style={{ padding: "1rem" }}>
                  <img src={MrRobot} alt="Mr Robot" />
                  <p>There has been a mistake.</p>
                </main>
              }
            />
          </Routes>
        </Router>
      </SnackbarProvider>
    </Provider>
  </Authenticator.Provider>
);
