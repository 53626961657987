import { AddBusiness, AddModeratorSharp } from "@mui/icons-material";
import { IconButton, Toolbar, Tooltip } from "@mui/material";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import ErrorBoundary from "../../lib/classes/ErrorBoundary";
import { adminActions } from "../../store/admin/adminSlice";
import AddPartnerBusiness from "../Admin/AddPartnerBusiness";
import AddPortalUser from "../Admin/AddPortalUser";

const AdminToolbar = () => {
  const { show_admin_new_user, show_admin_new_partner } = useSelector(
    (store) => store.user
  );
  const dispatch = useDispatch();

  const handleAddUser = () => {
    dispatch(adminActions.handleAddUserDialog());
  };

  const handleAddBusiness = () => {
    dispatch(adminActions.handleAddBusinessDialog());
  };

  return (
    <ErrorBoundary>
      <Toolbar>
        {show_admin_new_user && (
          <>
            <AddPortalUser />
            <Tooltip title="New Portal User">
              <span>
                <IconButton
                  onClick={handleAddUser}
                  color="primary"
                  size="large"
                >
                  <AddModeratorSharp />
                </IconButton>
              </span>
            </Tooltip>
          </>
        )}
        {show_admin_new_partner && (
          <>
            <AddPartnerBusiness />
            <Tooltip title="New Partner Business">
              <span>
                <IconButton
                  onClick={handleAddBusiness}
                  color="primary"
                  size="large"
                >
                  <AddBusiness />
                </IconButton>
              </span>
            </Tooltip>
          </>
        )}
      </Toolbar>
    </ErrorBoundary>
  );
};

export default AdminToolbar;
