import { DateTime } from "luxon";
/**
 * Wraps the luxon DateTime method toHuman() and reformats its return value for our purposes.
 * @param {string} toHuman: the return value from the luxon DateTime methods -> diffNow() -> diff.toHuman()
 */
function handleDiffToHuman(toHuman) {
  const [years, months, days] = toHuman.replaceAll("-", "").split(", ");
  const d = `${days.split(".")[0]} days`;
  if (years === "0 years" && months === "0 months") {
    return d;
  } else if (years === "0 years") {
    return `${months}, ${d}`;
  } else {
    return `${years}, ${months}, ${d}`;
  }
}
/**
 * Fully formats a given ISODate string using methods provided by the luxon library.
 * @param {string} expiry: an ISODate-like string.
 * @returns {ExpiryMetadata: {deficit: {number}, duration: {number}, str_float: {string}, status: {string}}}
 * */
function expiryConverter(expiry) {
  const expiration = DateTime.fromISO(expiry);
  const diff = expiration.diffNow(["years", "months", "days"]);
  if (Math.abs(diff.as("years").toPrecision(2)) > 0.99) {
    const years = diff.as("years");
    return {
      deficit:
        years.toPrecision(1) < 0 ? Math.abs(years.toPrecision(1)) : undefined,
      duration:
        years.toPrecision(1) > 0 ? Math.abs(years.toPrecision(1)) : undefined,
      str_float: years.toPrecision(2).toString(),
      readable: handleDiffToHuman(diff.toHuman()),
      status: Math.sign(years) === 1 ? "active" : "expired",
    };
  } else if (Math.floor(Math.abs(diff.as("months"))) > 0) {
    const months = diff.as("months");
    return {
      deficit:
        months.toPrecision(1) < 0 ? Math.abs(months.toPrecision(1)) : undefined,
      duration:
        months.toPrecision(1) > 0 ? Math.abs(months.toPrecision(1)) : undefined,
      str_float: months.toPrecision(2).toString(),
      readable: handleDiffToHuman(diff.toHuman()),
      status: Math.sign(months) === 1 ? "active" : "expired",
    };
  } else {
    const days = diff.as("days");
    return {
      deficit:
        Math.floor(days.toPrecision(1)) < 0
          ? Math.abs(days.toPrecision(1))
          : undefined,
      duration:
        Math.floor(days.toPrecision(1)) > 0
          ? Math.abs(days.toPrecision(1))
          : undefined,
      str_float: days.toPrecision(2).toString(),
      readable: handleDiffToHuman(diff.toHuman()),
      status: Math.sign(days) === 1 ? "active" : "expired",
    };
  }
}

export default expiryConverter;
