import { AddCircle } from '@mui/icons-material'
import { IconButton, Tooltip } from '@mui/material'
import React from 'react'
import { useSelector } from 'react-redux'

const AddCreditsButton = (props) => {
  const { adminProdData } = useSelector(store => store.admin);
  const { params, handleAddOpen } = props;
  
  const baseDataHelper = () => {
    for (const idx in adminProdData) {
      const baseData = {
        ...adminProdData[idx],
      };
      if (params.row.kind === baseData.kind) {
        return baseData;
      }
    }
  }

  const handleClick = () => {
    const helper = baseDataHelper();
    if (helper) {
      handleAddOpen(params.row.kind, helper.base_product, helper.use_as, helper.description);
    }
    if (!helper) {
      handleAddOpen(params.row.kind)
    }
  }

  return (
    <Tooltip title="Open a dialog to ADD credits for this product, or another selectable product.">
      <IconButton
        onClick={handleClick}
        size="small"
        style={{ ml: 8 }}
        tabIndex={params.hasFocus ? 0 : -1}
      ><AddCircle /></IconButton>
    </Tooltip>
  )
}

export default AddCreditsButton