import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { creditActions } from "../store/credits/creditsSlice";
import { subscriptionActions } from "../store/subscriptions/subscriptionsSlice";

const GetUserData = () => {
  const { updateCredits, creditsLoading } = useSelector(
    (store) => store.credits
  );
  const { updateSubs, loadingStateMachine } = useSelector(
    (store) => store.subs
  );
  const dispatch = useDispatch();

  React.useEffect(() => {
    const subsInterval = setInterval(() => {
      dispatch(subscriptionActions.setUpdateSubs());
    }, 599850);

    return () => clearInterval(subsInterval);
  }, [dispatch, updateSubs, loadingStateMachine]);

  React.useEffect(() => {
    const creditInterval = setInterval(() => {
      dispatch(creditActions.setUpdateCredits());
    }, 600000);
    return () => clearInterval(creditInterval);
  }, [dispatch, updateCredits, creditsLoading]);

  return <React.Fragment />;
};

export default GetUserData;
