import React from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Button,
  Card,
  Container,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  MenuItem,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { userActions } from "../../store/user/userSlice";
import { Close, RepeatOne } from "@mui/icons-material";
import { extendSub } from "../../store/subscriptions/subscriptionsSlice";
import { creditActions } from "../../store/credits/creditsSlice";
import { useDeferredValue } from "react";
import ErrorBoundary from "../../lib/classes/ErrorBoundary";

function useKind(kind, listing) {
  const productFilter = listing.filter((listing) => listing.base_product === kind);
  const prodIndex = productFilter.findIndex((element) => element.base_product === kind);
  const [product, setProduct] = React.useState(+productFilter[prodIndex]?.kind || +listing[0].kind);
  const productRef = React.useRef(0);

  function handleProduct(event) {
    setProduct(event.target.value);
  };

  return [ product, productRef, productFilter, handleProduct ];
}

function ExtendDialog(props) {
  const extendProps = useDeferredValue(props);
  const { showExtend } = useSelector((store) => store.user);
  const { creditListing } = useSelector((store) => store.credits);
  const dispatch = useDispatch();

  const [ product, productRef, productFilter, handleProduct ] = useKind(extendProps.productKind, creditListing);

  const handleClose = () => {
    dispatch(userActions.toggleExtendDialog());
  };

  const handleExtend = async (event) => {
    event.preventDefault();
    const prod = +productRef.current.value;
    dispatch(
      extendSub({
        ssid: extendProps.subscriptionId,
        identifier: extendProps.identifier,
        kind: +prod,
      })
    );
    dispatch(creditActions.spendOne(+prod));
    extendProps.update(true);
  };

  return (
    <Container>
      <Dialog
        maxWidth="xl"
        disableEnforceFocus
        open={showExtend}
        onClose={handleClose}
        aria-labelledby="modal-title"
        aria-describedby="modal-description"
        sx={{ justifyContent: "center" }}
      >
        <Card sx={{ backgroundColor: "#030303" }}>
          <DialogTitle>
            <Stack direction="row">
              <Typography id="modal-title" variant="inherit" sx={{ ml: "2px" }}>
                Renew subscription:
              </Typography>
              <Typography
                variant="inherit"
                sx={{ color: "#63a528", ml: "3px" }}
              >
                {" "}
                {extendProps.identifier}{" "}
              </Typography>
            </Stack>
            <Divider variant="middle" sx={{ my: "0.5px" }} />
          </DialogTitle>
          <DialogContent
            sx={{
              m: "2px",
              alignText: "center",
              justifyContent: "space-between",
            }}
          >
            <DialogContentText id="modal-description" variant="body2">
              You have the following product credits available to renew this
              subscription with.
            </DialogContentText>
            <ErrorBoundary>
              <TextField
                id="extend-select-product"
                label="Renew with product credit?"
                helperText="Select a product and credit to renew this licence with."
                select
                value={product}
                onChange={handleProduct}
                inputRef={productRef}
                sx={{ ml: "1vw", minWidth: "24vw", my: "4px" }}
              >
                {productFilter.map(
                  (option) =>
                    option.qty > 0 &&
                    option.use_as !== "demo" && (
                      <MenuItem key={option.id} value={option.kind}>
                        {option.description}
                      </MenuItem>
                    )
                )}
              </TextField>
            </ErrorBoundary>
          </DialogContent>
          <DialogActions sx={{ justifyContent: "space-between" }}>
            <Button
              variant="contained"
              onClick={handleClose}
              startIcon={<Close />}
            >
              Cancel
            </Button>
            <Button
              variant="contained"
              onClick={handleExtend}
              endIcon={<RepeatOne />}
            >
              Use Credit to Renew
            </Button>
          </DialogActions>
        </Card>
      </Dialog>
    </Container>
  );
};

export default ExtendDialog;
