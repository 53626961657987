import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { api_path } from "../../config/site";
import { enqueueSnackbar } from "../notifications/actions";
import { getAccessToken } from "../auth/signinSlice";

const initialState = {
  adminData: [],
  adminPartners: [],
  adminProdData: [],
  adminProdsSorted: [],
  adminBaseProds: [],
  adminRenewProducts: [],
  adminCreateProducts: [],
  adminDemoProducts: [],
  updateAdmin: "yes",
  updateAdminBool: true,
  adminLoading: true,
  showAddDialog: false,
  showRemoveDialog: false,
  showSelfServ: false,
  showAddUserDialog: false,
  showAddPartnerDialog: false,
};

export const getAdminData = createAsyncThunk(
  "admin/getAdmin",
  async (thunkAPI) => {
    try {
      const accessToken = getAccessToken();
      const DEFAULT_INIT = {
        mode: "cors",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${accessToken}`,
        },
      };
      const first = await fetch(api_path + `/admin/credits/`, DEFAULT_INIT);
      if (!first.ok) {
        throw new Error("Admin credit system data could not be retrieved.");
      }
      const adminData = await first.json();

      const third = await fetch(
        api_path + `/admin/downstream-partner-info/`,
        DEFAULT_INIT
      );
      if (!third.ok) {
        throw new Error(
          "Admin downstream partner data could not be retrieved."
        );
      }
      const partnerData = await third.json();

      const assignedData = [];
      for (const x in partnerData) {
        const partnerObj = {
          ...partnerData[x],
          kinds: [],
          subs: [],
          activeCredits: 0,
          subCount: 0,
        };
        for (const y in adminData) {
          const adminObj = {
            ...adminData[y],
          };

          if (partnerObj.id === adminObj.partner_id) {
            const { kind, qty } = adminObj;
            partnerObj.kinds.push({ kind: kind, qty: qty });
            partnerObj.activeCredits += qty;
          }
        }

        assignedData.push(partnerObj);
      }

      return { assignedData: assignedData, partnerData: partnerData };
    } catch (error) {
      return thunkAPI.rejectWithValue(
        "There was an error communicating with the Admin API: " + error
      );
    }
  }
);

export const getAdminProds = createAsyncThunk(
  "admin/getAdminProds",
  async (thunkAPI) => {
    try {
      const accessToken = getAccessToken();
      const DEFAULT_INIT = {
        mode: "cors",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${accessToken}`,
        },
      };
      const response = await fetch(api_path + `/admin/products`, DEFAULT_INIT);
      if (!response.ok) {
        throw new Error("Admin products data could not be retrieved.");
      }
      const adminProds = await response.json();
      const prodArray = [];
      const baseProds = new Set();
      for (const idx in adminProds) {
        const admProd = {
          ...adminProds[idx],
        };

        if (
          admProd.description.includes("plus Device") ||
          admProd.description.includes("New")
        ) {
          Object.assign(admProd, { use_as: "create" });
        }

        if (
          !admProd.description.includes("plus Device") &&
          !admProd.description.includes("New")
        ) {
          Object.assign(admProd, { use_as: "renew" });
        }

        if (admProd.description.includes("Demo")) {
          Object.assign(admProd, { demo: "demo" });
        }
        baseProds.add(admProd.base_product);
        prodArray.push(admProd);
      }

      const sortedArray = [];

      baseProds.forEach((setBase) => {
        const sorted = prodArray.filter(
          (prods) => prods.base_product === setBase
        );
        const creators = [];
        const renewals = [];
        sorted.forEach((element) => {
          if (element.use_as === "create") {
            creators.push(element);
          }
          if (element.use_as === "renew") {
            renewals.push(element);
          }
        });
        sortedArray.push({
          base: setBase,
          products: { creators: creators, renewals: renewals },
        });
      });

      return { prodArray: prodArray, sortedArray: sortedArray, baseProducts: [...baseProds] };
    } catch (err) {
      return thunkAPI.rejectWithValue(
        "There was an error retrieving products from the admin API: " + err
      );
    }
  }
);

export const assignCredits = (realJson) => {
  return async (dispatch) => {
    const sendReq = async () => {
      const accessToken = getAccessToken();
      const response = await fetch(api_path + `/admin/credits/add/`, {
        method: "POST",
        mode: "cors",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${accessToken}`,
        },
        body: JSON.stringify(realJson),
      });
      if (!response.ok) {
        throw new Error("Could not post credit assignment.");
      }
      const resData = await response.json();
      return resData;
    };
    try {
      const reqData = await sendReq();
      if (reqData === "Success.") {
        return dispatch(
          enqueueSnackbar({
            message: "Credits have been assigned successfully!",
            options: {
              variant: "success",
              autoHideDuration: 12000,
              resumeHideDuration: 800,
            },
          })
        );
      }
    } catch (error) {
      return dispatch(
        enqueueSnackbar({
          message: "There was a problem sending assignment data.",
          options: {
            variant: "error",
            autoHideDuration: 12000,
            resumeHideDuration: 800,
          },
        })
      );
    }
  };
};

export const removeCredits = (realJson) => {
  return async (dispatch) => {
    const sendRemove = async () => {
      const accessToken = getAccessToken();
      const removeRes = await fetch(api_path + `/admin/credits/remove/`, {
        method: "POST",
        mode: "cors",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${accessToken}`,
        },
        body: JSON.stringify(realJson),
      });
      if (!removeRes.ok) {
        throw new Error("Could not post credit removal.");
      }
      const removeData = await removeRes.json();

      return removeData;
    };
    try {
      const doRemoveRequest = await sendRemove();
      if (doRemoveRequest === "Success.") {
        return dispatch(
          enqueueSnackbar({
            message: "Credits have been removed successfully!",
            options: {
              variant: "success",
              autoHideDuration: 12000,
              resumeHideDuration: 800,
            },
          })
        );
      }
    } catch (err) {
      return dispatch(
        enqueueSnackbar({
          message: "There was a problem sending the removal request.",
          options: {
            variant: "error",
            autoHideDuration: 12000,
            resumeHideDuration: 800,
          },
        })
      );
    }
  };
};

export const createPortalUser = (input) => {
  return async (dispatch) => {
    const sendNewUser = async () => {
      const accessToken = getAccessToken();
      const createRequest = await fetch(api_path + `/admin/users/`, {
        method: "POST",
        mode: "cors",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${accessToken}`,
        },
        body: JSON.stringify(input),
      });
      if (!createRequest.ok) {
        throw new Error("Could not post user addition.");
      }
      const newUserResponse = await createRequest.json();

      return newUserResponse;
    };
    try {
      const doSendNewUser = await sendNewUser();
      if (doSendNewUser === "Success.") {
        return dispatch(
          enqueueSnackbar({
            message: `User ${input.username} has been created under ${input.partner_id}!`,
            options: {
              variant: "success",
              autoHideDuration: 12000,
              resumeHideDuration: 800,
            },
          })
        );
      }
    } catch (error) {
      return dispatch(
        enqueueSnackbar({
          message: "There was a problem sending the new user to the back end.",
          options: {
            variant: "error",
            autoHideDuration: 12000,
            resumeHideDuration: 800,
          },
        })
      );
    }
  };
};

export const createDownstreamBusiness = (json) => {
  return async (dispatch) => {
    const sendDownstream = async () => {
      const accessToken = getAccessToken();
      const downstreamRequest = await fetch(api_path + `/admin/partners/`, {
        method: "POST",
        mode: "cors",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${accessToken}`,
        },
        body: JSON.stringify(json),
      });
      if (!downstreamRequest.ok) {
        throw new Error(
          "There was an error submitting the new partner business."
        );
      }
      const downstreamData = await downstreamRequest.json();

      return downstreamData;
    };
    try {
      const doSendDownstream = await sendDownstream();

      if (doSendDownstream === "Success.") {
        return dispatch(
          enqueueSnackbar({
            message: `New partner business ${json.new_partner_name} has been created!`,
            options: {
              variant: "success",
              autoHideDuration: 12000,
              resumeHideDuration: 800,
            },
          })
        );
      }
    } catch (e) {
      return dispatch(
        enqueueSnackbar({
          message: `The server returned an error response status, partner business could not be added.`,
          options: {
            variant: "error",
            autoHideDuration: 12000,
            resumeHideDuration: 800,
          },
        })
      );
    }
  };
};

const adminSlice = createSlice({
  name: "admin",
  initialState: initialState,
  reducers: {
    sortByCreditType: {
      reducer: (state) => {
        const createProducts = [];
        const renewProducts = [];
        const demoProducts = [];
        for (const indx in state.adminProdData) {
          const admnProduct = {
            ...state.adminProdData[indx],
          };

          if (admnProduct.use_as === "create") {
            createProducts.push(admnProduct);
          }
          if (admnProduct.use_as === "renew") {
            renewProducts.push(admnProduct);
          }

          if (admnProduct.demo && admnProduct.demo === "demo") {
            demoProducts.push(admnProduct);
          }
        }
        state.adminCreateProducts = createProducts;
        state.adminRenewProducts = renewProducts;
        state.adminDemoProducts = demoProducts;
      },
    },
    handleSelfServ: {
      reducer: (state) => {
        state.showSelfServ = !state.showSelfServ;
      }
    },
    handleRemoveDialog: {
      reducer: (state) => {
        state.showRemoveDialog = !state.showRemoveDialog;
      }
    },
    handleAddDialog: {
      reducer: (state) => {
        state.showAddDialog = !state.showAddDialog;
      }
    },
    setUpdateAdmin: {
      reducer: (state) => {
        state.updateAdminBool = !state.updateAdminBool;
      }
    },
    updateAdminState: {
      reducer: (state) => {
        if (state.updateAdminBool === true) {
          state.updateAdmin = "yes";
        }
        if (state.updateAdminBool === false) {
          state.updateAdmin = "no";
        }
      }
    },
    handleAddUserDialog: {
      reducer: (state) => {
        state.showAddUserDialog = !state.showAddUserDialog;
      }
    },
    handleAddBusinessDialog: {
      reducer: (state) => {
        state.showAddPartnerDialog = !state.showAddPartnerDialog;
      }
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getAdminData.pending, (state) => {
      state.adminLoading = true;
      state.updateAdmin = "yes";
    });
    builder.addCase(getAdminData.fulfilled, (state, action) => {
      state.adminLoading = false;
      state.adminData = action.payload.assignedData;
      state.adminPartners = action.payload.partnerData;
      state.updateAdmin = "no";
    });
    builder.addCase(getAdminData.rejected, (state, action) => {
      state.adminLoading = false;
      state.updateAdmin = "yes";
    });
    builder.addCase(getAdminProds.pending, (state) => {
      state.adminLoading = true;
      state.updateAdmin = "yes";
    });
    builder.addCase(getAdminProds.fulfilled, (state, action) => {
      state.adminLoading = false;
      state.adminProdData = action.payload.prodArray;
      state.adminProdsSorted = action.payload.sortedArray;
      state.adminBaseProds = action.payload.baseProducts;
      state.updateAdmin = "no";
    });
    builder.addCase(getAdminProds.rejected, (state, action) => {
      state.adminLoading = false;
      state.updateAdmin = "yes";
    });
  },
});

export const adminActions = adminSlice.actions;

export default adminSlice.reducer;
