import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import {Auth} from "aws-amplify";
import {ENV_AUTH_PROVIDER, ENV_CLIENTID} from "../../config/site";

const initialState = {
  isLoggedIn: false,
  userSession: {
    username:
      localStorage.getItem(
        ENV_AUTH_PROVIDER + "." + ENV_CLIENTID + ".LastAuthUser"
      ) || "",
    accessToken:
      localStorage.getItem(
        ENV_AUTH_PROVIDER +
          "." +
          ENV_CLIENTID +
          "." +
          localStorage.getItem(
            ENV_AUTH_PROVIDER + "." + ENV_CLIENTID + ".LastAuthUser"
          ) +
          ".accessToken"
      ) || "",
    sessionExpiry:
      localStorage.getItem(
        ENV_AUTH_PROVIDER +
          "." +
          ENV_CLIENTID +
          "." +
          localStorage.getItem(
            ENV_AUTH_PROVIDER + "." + ENV_CLIENTID + ".LastAuthUser"
          ) +
          ".expirationTime"
      ) || 0,
    sessionIat: 0,
  },
};

const getStoredLastAuthUser = () => {
  return localStorage.getItem(
      ENV_AUTH_PROVIDER + "." + ENV_CLIENTID + ".LastAuthUser"
  );
};

export const getAccessToken = () => {
  const authedUser = getStoredLastAuthUser();
  return localStorage.getItem(
      ENV_AUTH_PROVIDER + "." + ENV_CLIENTID + "." + authedUser + ".accessToken"
  );
};

const clearStoredTokens = () => {
  const storedLastAuthUser = getStoredLastAuthUser();
  localStorage.removeItem(
    ENV_AUTH_PROVIDER +
      "." +
      ENV_CLIENTID +
      "." +
      storedLastAuthUser +
      ".clockDrift"
  );
  localStorage.removeItem(
    ENV_AUTH_PROVIDER +
      "." +
      ENV_CLIENTID +
      "." +
      storedLastAuthUser +
      ".idToken"
  );
  localStorage.removeItem(
    ENV_AUTH_PROVIDER +
      "." +
      ENV_CLIENTID +
      "." +
      storedLastAuthUser +
      ".accessToken"
  );
  localStorage.removeItem(
    ENV_AUTH_PROVIDER +
      "." +
      ENV_CLIENTID +
      "." +
      storedLastAuthUser +
      ".refreshToken"
  );
  localStorage.removeItem(
    ENV_AUTH_PROVIDER +
      "." +
      ENV_CLIENTID +
      "." +
      storedLastAuthUser +
      ".expirationTime"
  );
  localStorage.removeItem(
    ENV_AUTH_PROVIDER + "." + ENV_CLIENTID + ".LastAuthUser"
  );
  localStorage.removeItem("CopperheadPartner_user");
  localStorage.removeItem("CopperheadPartner_name");
  localStorage.removeItem("CopperheadPartner_id");
  if (localStorage.getItem("CopperheadDistributorPartner")) {
    localStorage.removeItem("CopperheadDistributorPartner");
  }
};

export const awsLogout = createAsyncThunk(
  "signin/awsLogout",
  async (thunkAPI) => {
    try {
      return await Auth.signOut();
    } catch {
      return thunkAPI.rejectWithValue(
        "You broke redux toolkit trying to logout... wow."
      );
    }
  }
);

const signinSlice = createSlice({
  name: "signin",
  initialState: initialState,
  reducers: {
    login(state) {
      state.isLoggedIn = true;
    },
    setCognitoUser(state, action) {
      state.userSession.username = action.payload.username;
      state.userSession.accessToken = action.payload.accessToken;
      state.userSession.sessionExpiry = action.payload.sessionExpiry;
      state.userSession.sessionIat = action.payload.sessionIat;
    },
    logout(state) {
      state.userSession.username = "";
      state.userSession.accessToken = "";
      state.userSession.sessionExpiry = 0;
      state.userSession.sessionIat = 0;
      state.isLoggedIn = false;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(awsLogout.fulfilled, (state) => {
      state.userSession.username = "";
      state.userSession.accessToken = "";
      state.userSession.sessionExpiry = 0;
      state.userSession.sessionIat = 0;
      state.isLoggedIn = false;
      clearStoredTokens();
      window.location.replace("/");
    });
  },
});

export const signinActions = signinSlice.actions;

export default signinSlice.reducer;
