import React from "react";
import { Facebook, Twitter, YouTube, LinkedIn } from "@mui/icons-material";
import {
  Box,
  Link,
  ListItemButton,
  ListItemIcon,
  Stack,
  Typography,
} from "@mui/material";
import PaletteModeSwitch from "../custom/PaletteModeSwitch";
import "./FooterIcons.css";

function Copyright() {
  return (
    <React.Fragment>
      <Typography
        sx={{
          textShadow: "1px 1px 2px black",
          fontFamily: "Big Shoulders Text",
          fontSize: 19,
        }}
        variant="body1"
        color="#fff"
      >
        {"Copyright © "}
        <Link color="#fff" href="https://copperhead.co/">
          Copperhead
        </Link>{" "}
        {new Date().getFullYear()}
        {"."}
      </Typography>
    </React.Fragment>
  );
}

export default function StickyFooter() {
  return (
    <React.Fragment>
      <Box
        component="footer"
        sx={{
          p: 1,
          display: "flex",
          flexDirection: "row",
          minHeight: "3rem",
          backgroundColor: (theme) =>
            theme.palette.mode === "dark"
              ? theme.palette.secondary.main
              : theme.palette.primary.main,
          alignItems: "center",
          alignContent: "center",
          justifyContent: "space-between",
        }}
      >
        <Copyright />
        <Stack direction="row" spacing={0}>
          <PaletteModeSwitch />
          <Link href="https://www.linkedin.com/company/copperhead-security">
            <ListItemButton disableGutters disableRipple dense>
              <ListItemIcon
                sx={{
                  mx: -2,
                  alignItems: "center",
                  alignContent: "center",
                  justifyContent: "center",
                  justifyItems: "center",
                  color: "whitesmoke",
                }}
              >
                <LinkedIn className="footer-icons" />
              </ListItemIcon>
            </ListItemButton>
          </Link>
          <Link href="https://www.twitter.com/copperheadsec">
            <ListItemButton disableGutters disableRipple dense>
              <ListItemIcon
                sx={{
                  mx: -2,
                  alignItems: "center",
                  alignContent: "center",
                  justifyContent: "center",
                  justifyItems: "center",
                  color: "whitesmoke",
                }}
              >
                <Twitter className="footer-icons" />
              </ListItemIcon>
            </ListItemButton>
          </Link>
          <Link href="https://www.facebook.com/secureyourandroid">
            <ListItemButton disableGutters disableRipple dense>
              <ListItemIcon
                sx={{
                  mx: -2,
                  alignItems: "center",
                  alignContent: "center",
                  justifyContent: "center",
                  justifyItems: "center",
                  color: "whitesmoke",
                }}
              >
                <Facebook className="footer-icons" />
              </ListItemIcon>
            </ListItemButton>
          </Link>
          <Link href="https://www.youtube.com/channel/UCQMaZY3msrkR8JKOJvqIr2A">
            <ListItemButton disableGutters disableRipple dense>
              <ListItemIcon
                sx={{
                  mx: -2,
                  alignItems: "center",
                  alignContent: "center",
                  justifyContent: "center",
                  justifyItems: "center",
                  color: "whitesmoke",
                }}
              >
                <YouTube className="footer-icons" />
              </ListItemIcon>
            </ListItemButton>
          </Link>
        </Stack>
      </Box>
    </React.Fragment>
  );
}
