export const ENV_CLIENTID = process.env.REACT_APP_AUTH_CLIENTID;
export const ENV_AUTH_PROVIDER = process.env.REACT_APP_AUTH_PROVIDER;
export const app_version = process.env.REACT_APP_VERSION;
export const name = "Copperhead";
export const short_name = "copperhead";
export const corporate_name = "Copperhead Limited";
export const corporate_description = "Cybersecurity company specialising in secure Operating Systems.";
export const corporate_phone = "+1 855-684-5569";
export const general_email = "info@copperhead.co";
export const media_email = "media@copperhead.co";
export const description = "Copperhead is an information security firm located in Toronto, Canada. Specializing in protecting mobile data and devices through security assessments and secure endpoint deployments.";
export const brand_colour = "#a2466c";
export const background_colour = "#000000";
export const api_path = process.env.REACT_APP_API_URL;
export const url = process.env.REACT_APP_BASE_URL;
export const google_analytics_id = "UA-137811622-1";
export const address = {
    street: "112 Elizabeth St",
    city: "Toronto",
    region: "Ontario",
    country: "Canada",
    postal: "M5G 1P5"
};
export const social = {
    twitter: "copperheadsec",
    facebook: "facebook.com/secureyourandroid",
    github: "copperheados",
    linkedin: "copperhead-security",
    reddit: "/r/Copperhead/"
};
