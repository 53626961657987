const wordArray = [
  "Apple",
  "Aardvark",
  "Arch",
  "Arugula",
  "Bell",
  "Big",
  "Basil",
  "Bear",
  "Boar",
  "Beast",
  "Best",
  "Boat",
  "Banana",
  "Bean",
  "Bee",
  "Cat",
  "Cucumber",
  "Carnation",
  "Critter",
  "Chive",
  "Cornflower",
  "Cucumber",
  "Dog",
  "Daisy",
  "Daffodil",
  "Dill",
  "Eagle",
  "Eggplant",
  "Evergreen",
  "Friendly",
  "Fuzzy",
  "Fennel",
  "Fern",
  "Gallant",
  "Garden",
  "Gardenia",
  "Gleaming",
  "Glowing",
  "Gravel",
  "Happy",
  "Heart",
  "Inset",
  "Ink",
  "Javelin",
  "Jelly",
  "Kelp",
  "Kangaroo",
  "Lion",
  "Llama",
  "Lavender",
  "Lovage",
  "Minnow",
  "Muskrat",
  "Neon",
  "Nano",
  "Open",
  "Orchid",
  "Okra",
  "Posey",
  "Poney",
  "Quasar",
  "Quilt",
  "Rose",
  "Raccoon",
  "Salt",
  "Snail",
  "Tangent",
  "Tarantula",
  "Urban",
  "Ultimate",
  "Valley",
  "Veneer",
  "Weather",
  "Worldly",
  "Wordy",
  "Woodruff",
  "Xylophone",
  "Zero",
  "Zebra",
  "Mint",
  "Bergamot",
  "Geranium",
  "Rosemary",
  "Pineapple",
  "Elderberry",
  "Lilac",
  "Marigold",
  "Sunflower",
  "Daylily",
  "Chicory",
  "Dandelion",
  "Thyme",
  "Clover",
  "Bean",
  "Basil",
  "Bread",
  "Basket",
  "Beam",
  "Belt",
  "Linden",
  "Violet",
  "Red",
  "Green",
  "Blue",
  "Sun",
  "Moon",
  "Sky",
  "Soil",
  "Tulip",
  "Poppy",
  "Eggplant",
  "Canteloupe",
  "Oak",
  "Camellia",
  "Saguaro",
  "Peach",
  "Quark",
  "Atom",
  "Electron",
  "Proton",
  "Zero",
  "Sword",
  "Shield",
  "Armor",
  "Flag",
  "Song",
  "Anthem",
  "Mild",
  "Morose",
  "Meager",
  "Mindful",
  "Merry",
  "Glory",
  "Truth",
  "Dark",
  "Light",
  "Blank",
  "Blink",
  "Quake",
  "Doom",
  "Wolf",
  "Mouse",
  "Tiger",
];
const end = wordArray.length - 1;

const getRandomInt = (min, max) => {
  min = Math.ceil(min);
  max = Math.floor(max);
  return Math.floor(Math.random() * (max - min) + min);
};

function getRandomWord() {
  return wordArray[getRandomInt(1, end)];
}

export default getRandomWord;
