import React from "react";
import { useSelector } from "react-redux";
import { Navigate, useLocation } from "react-router-dom";

const RequireDistrib = ({ children }) => {
  const { show_credits_admin } = useSelector((store) => store.user);
  const location = useLocation();

  if (!show_credits_admin) {
    return <Navigate to="/" state={{ location }} replace />;
  }

  return children;
};

export default RequireDistrib;
