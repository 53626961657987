import React from "react";

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(error) {
    return { hasError: true };
  }

  componentDidCatch(error, errorInfo) {
    console.error(error, errorInfo);
  }

  render() {
    if (this.state.hasError) {
      return (
        <React.Fragment>
          <h1>An error occurred attempting to load this page.</h1>
          <h4>
            The issue has been automatically reported, thank you for your
            patience while we resolve the problem.
          </h4>
        </React.Fragment>
      );
    }

    return this.props.children;
  }
}

export default ErrorBoundary;
