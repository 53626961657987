import React, { Component } from "react";
import { store } from "../store";

export default class Amplifier extends Component {
  constructor(props) {
    super(props);

    this.storeListener = this.storeListener.bind(this);

    this.state = {
      userSession: {
        username: "",
        accessToken: "",
        sessionExpiry: 0,
        sessionIat: 0,
      },
    };
  }

  componentDidMount() {
    this.unsubscribeStore = store.subscribe(this.storeListener);
  }

  componentWillUnmount() {
    this.unsubscribeStore();
  }

  storeListener() {
    const state = store.getState();
    this.setState({ userSession: state.userSession });
  }

  render() {
    return <React.Fragment />;
  }
}
