import { AddModerator, Message } from "@mui/icons-material";
import {
  Button,
  Card,
  CardActions,
  CardContent,
  Typography,
} from "@mui/material";
import React from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import ErrorBoundary from "../../lib/classes/ErrorBoundary";

const ZeroCard = () => {
  const { show_credits_admin } = useSelector((store) => store.user);
  const navigate = useNavigate();

  const handleNavigate = (event) => {
    event.preventDefault();

    navigate("/credits/admin/");
  };

  return (
    <ErrorBoundary>
      <Card
        variant="outlined"
        sx={{
          minHeight: "21.5vh",
          padding: "10px",
          textAlign: "center",
        }}
      >
        <CardContent sx={{ mb: "3px" }}>
          {!show_credits_admin && (
            <Typography variant="body2" sx={{ maxWidth: "38.5vw", my: "2px" }}>
              No products or credits found for this account. Please speak with
              your Copperhead Partner Representative to purchase licence credits
              to exchange for QR codes.
            </Typography>
          )}
          {show_credits_admin && (
            <Typography variant="body2" sx={{ maxWidth: "38.5vw", my: "2px" }}>
              No credits or products have been assigned yet. Visit the Credits
              admin page to assign yourself credits, or assign credits to
              downstream resellers.
            </Typography>
          )}
        </CardContent>
        {!show_credits_admin && (
          <CardActions
            sx={{
              justifyContent: "space-between",
              mt: "3px",
            }}
          >
            <Button endIcon={<Message />}>Get in touch...</Button>
          </CardActions>
        )}
        {show_credits_admin && (
          <CardActions sx={{ justifyContent: "end", mt: "3px" }}>
            <Button startIcon={<AddModerator />} onClick={handleNavigate}>
              Credits Admin
            </Button>
          </CardActions>
        )}
      </Card>
    </ErrorBoundary>
  );
};

export default ZeroCard;
