import { useSelector } from "react-redux";
import { darkMode } from "../themes/dark";
import { lightMode } from "../themes/light";

const useThemeSelector = () => {
  const { paletteMode } = useSelector((store) => store.ui);

  if (paletteMode === "dark") {
    return darkMode;
  }

  if (paletteMode === "light") {
    return lightMode;
  }
}

export default useThemeSelector;