import React from "react";
import { Box } from "@mui/material";
import { useSelector } from "react-redux";
import ErrorBoundary from "../../lib/classes/ErrorBoundary";
import ProductCard from "./ProductCard/ProductCard";
import ZeroCard from "./ZeroCard";
import gridBox from "./gridBox";

const GridViewCreators = () => {
  const { creatorListing } = useSelector((store) => store.credits);

  return (
    <ErrorBoundary>
      {creatorListing.length > 0 ? (
        creatorListing.map((credit) => (
          <ProductCard key={credit.kind} credit={credit} />
        ))
      ) : (
        <Box component="span" sx={gridBox}>
          <ZeroCard />
        </Box>
      )}
    </ErrorBoundary>
  );
};

export default GridViewCreators;
