import { Avatar } from "@mui/material";
import React from "react";
import PropTypes from "prop-types";
import CryptoLite from "../../static/img/products/CryptoLite.png";
import CryptoLock from "../../static/img/products/CryptoLock.png";
import CryptoWork from "../../static/img/products/CryptoWork.png";

function CryptoCloud(props) {
  if (props.subprod === "CryptoLite") {
    return (

      <Avatar
        variant={props.variant}
        src={CryptoLite}
        alt={props.subprod}
        sx={props.sx} />

    );
  } else if (props.subprod === "CryptoLock") {
    return (

      <Avatar
        variant={props.variant}
        src={CryptoLock}
        alt={props.subprod}
        sx={props.sx} />

    );
  } else if (props.subprod === "CryptoWork") {
    return (

      <Avatar
        variant={props.variant}
        src={CryptoWork}
        alt={props.subprod}
        sx={props.sx} />

    );
  } else {
    throw new Error(
      "A subprod prop must be provided for this component to render."
    );
  }
}

CryptoCloud.propTypes = {
  subprod: PropTypes.string.isRequired,
};

export default CryptoCloud;
