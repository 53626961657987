import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import {enqueueSnackbar} from "../notifications/actions";
import {api_path} from "../../config/site";
import {getAccessToken} from "../auth/signinSlice";

function getCreditDescType(desc) {
  const d = desc.replaceAll(" ", "_");
  if (d.includes("_New") || d.includes("_plus_Device")) {
    return "create";
  } else if (d.includes("_Renew")) {
    return "renew";
  } else if (d.includes("_Demo")) {
    return "demo";
  } else {
    return "create";
  }
}

export function mapCreditsToProducts(
	products = [],
	credits = [],
) {
	const mappedCredits = [];
  credits.forEach((credit) => {
    products.forEach((product) => {
    if (product.kind === credit.kind) {
      mappedCredits.push({
        kind: product.kind,
        base_product: product.base_product,
        description: product.description,
        time: product.time,
        qty: credit.qty,
        use_as: getCreditDescType(product.description)
      });
    }
    });
	});
	return mappedCredits;
}

const initialState = {
  creditListing: [],
  creatorListing: [],
  renewalListing: [],
  creditsLoading: "idle",
  showRenewals: false,
  updateCredits: "no",
  creditErrors: [],
};
async function creditsRequest() {
  const partner_id = localStorage.getItem("CopperheadPartner_id");
  const accessToken = getAccessToken();
  const options = {
    mode: "cors",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${accessToken}`,
    },
  };

  const creditsResponse = await fetch(
      api_path + `/partner/${+partner_id}/credits`,
      options
  );
  const creditsJson = await creditsResponse.json();
  if (!creditsResponse.ok) {
    throw new Error(creditsResponse.message || "Unable to retrieve credit info.");
  }

  const productResponse = await fetch(
      api_path + `/partner/${+partner_id}/products`,
      options
  );
  const productsJson = await productResponse.json();
  if (!productResponse.ok) {
    throw new Error(productResponse.message || "Unable to retrieve product info.");
  }
  if (!creditsJson || !productsJson) {
    throw new Error("Server returned an invalid response body.");
  }
  return mapCreditsToProducts(productsJson, creditsJson);
}

export const getCreditData = createAsyncThunk(
  "credits/getCreditData",
  async (_,thunkAPI) => {
    try {
      const credits = await creditsRequest();
      return credits;
    } catch (error) {
      console.error(error);
      return thunkAPI.rejectWithValue(
        "You broke redux toolkit fetching the credits."
      );
    }
  }
);

export const spendCredit = (realJson) => {
  return async (dispatch) => {
    const sendRequest = async () => {
      const accessToken = getAccessToken();
      const partner_id = localStorage.getItem("CopperheadPartner_id");
      const response = await fetch(
        api_path + `/partner/${partner_id}/subscription/new`,
        {
          method: "POST",
          mode: "cors",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${accessToken}`,
          },
          body: JSON.stringify(realJson),
        }
      );
      if (!response.ok) {
        throw new Error("New sub post is still broken and its your fault");
      }
      const data = await response.json();
        if (!data) {
          throw new Error("Nope");
        }
      return data;
    };

    try {
      const resData = await sendRequest();

      if (resData?.status === "Success") {
        dispatch(
          enqueueSnackbar({
            message: "Licence created successfully!",
            options: {
              variant: "success",
              autoHideDuration: 12000,
              resumeHideDuration: 800,
            },
          })
        );
      }

      return resData;
    } catch (error) {
      dispatch(
        enqueueSnackbar({
          message: `There was a problem sending creation data.`,
          options: {
            variant: "error",
            autoHideDuration: 12000,
            resumeHideDuration: 800,
          },
        })
      );

      return error;
    }
  };
};

const creditsSlice = createSlice({
  name: "credits",
  initialState: initialState,
  reducers: {
    spendOne(state, { payload }) {
      const currentCredit = state.creditListing.findIndex(
        (credit) => credit.kind === payload
      );
      state.creditListing[currentCredit].qty--;
    },
    setUpdateCredits(state) {
      if (state.updateCredits === "no") {
        state.updateCredits = "yes";
      } else {
        state.updateCredits = "no";
      }
    },
    sortByType(state) {
      const creators = [];
      const renewals = [];
      for (const key in state.creditListing) {
        const credit = {
          ...state.creditListing[key],
        };
        if (credit.use_as === "create") {
          creators.push(credit);
        }
        if (credit.use_as === "renew") {
          renewals.push(credit);
        }
        if (credit.use_as === "demo") {
          creators.push(credit);
        }
      }
      state.creatorListing = creators;
      state.renewalListing = renewals;
    },
    toggleRenewals(state) {
      state.showRenewals = !state.showRenewals;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getCreditData.pending, (state) => {
      state.creditsLoading = "pending";
    });
    builder.addCase(getCreditData.fulfilled, (state, action) => {
      state.creditsLoading = "idle";
      state.creditListing = action.payload;
      state.updateCredits = "no";
    });
    builder.addCase(getCreditData.rejected, (state, action) => {
      state.creditsLoading = "idle";
      state.updateCredits = "no";
      state.creditErrors = state.creditErrors.push(action.payload);
    });
  },
});

export const creditActions = creditsSlice.actions;

export default creditsSlice.reducer;
