import React from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  GridToolbarExport,
  GridFilterPanel,
  GridToolbarDensitySelector,
  GridToolbarColumnsButton,
  GridToolbarContainer,
} from "@mui/x-data-grid";
import { useTheme } from "@mui/material/styles";
import {
  Box,
  Button,
  Divider,
  IconButton,
  Stack,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { Refresh } from "@mui/icons-material";
import { adminActions } from "../../store/admin/adminSlice";
import { getSubscriptionData } from "../../store/subscriptions/subscriptionsSlice";
import AdminToolbar from "./AdminToolbar";
import "./CustomToolbar.css";

// props.header: string, typography to show in toolbar
// props.showRefresh: boolean, show the (currently not pointable) refresh admindata button
// props.refreshAdmin and props.refreshSubs each prop is required for the refresh button
// to point to a function.

const CustomToolbar = (props) => {
  const { show_credits_admin } = useSelector((store) => store.user);
  const muiTheme = useTheme();
  const xsScreen = useMediaQuery(muiTheme.breakpoints.up("xs"));
  const smScreen = useMediaQuery(muiTheme.breakpoints.up("sm"));
  const mdScreen = useMediaQuery(muiTheme.breakpoints.up("md"));
  const lgScreen = useMediaQuery(muiTheme.breakpoints.up("lg"));
  const xlScreen = useMediaQuery(muiTheme.breakpoints.up("xl"));
  const dispatch = useDispatch();

  function clickHandler() {
    if (props.refreshAdmin) {
      dispatch(adminActions.setUpdateAdmin());
    } else if (props.refreshSubs) {
      dispatch(getSubscriptionData());
    } else {
      return;
    }
  }

  function serviceHandler() {
    dispatch(adminActions.handleSelfServ());
  }

  if (lgScreen || xlScreen) {
    return (
      <Box>
        <Stack direction="row" justifyContent="space-between">
          <Typography sx={{ p: 1 }}>{props.header}</Typography>
          {show_credits_admin && <AdminToolbar />}
          <GridToolbarContainer>
            <GridToolbarDensitySelector />
            <GridToolbarColumnsButton />
            {show_credits_admin && <GridToolbarExport sx={{ p: 2, mr: 1 }} />}
          </GridToolbarContainer>
        </Stack>
        <Stack direction="row" justifyContent="space-between">
          <GridFilterPanel
            sx={{
              "& .MuiSvgIcon-root": { color: "#3c3c3c" },
              "& .MuiSvgIcon-root:hover": { color: "#63a528" },
            }}
          />
          {props.showSelfServ && (
            <Button variant="contained" sx={{ p: 2 }} onClick={serviceHandler}>
              Self-service credits
            </Button>
          )}
          {props.showRefresh && (
            <IconButton
              aria-label="Refresh content"
              className="refresh-btn"
              onClick={clickHandler}
            >
              <Refresh sx={{ color: "#63A528" }} className="refresh-icon" />
            </IconButton>
          )}
        </Stack>
        <Divider />
      </Box>
    );
  }

  if (mdScreen) {
    return (
      <Box>
        <Stack direction="row" justifyContent="space-between">
          <Typography sx={{ p: 1 }}>{props.header}</Typography>
          {show_credits_admin && <AdminToolbar />}
          <GridToolbarContainer>
            <GridToolbarColumnsButton />
            {show_credits_admin && <GridToolbarExport sx={{ p: 2, mr: 1 }} />}
          </GridToolbarContainer>
        </Stack>

        <GridFilterPanel
          sx={{
            "& .MuiSvgIcon-root": { color: "#3c3c3c" },
            "& .MuiSvgIcon-root:hover": { color: "#63a528" },
          }}
        />
        {props.showSelfServ && (
          <Button variant="contained" sx={{ p: 2 }} onClick={serviceHandler}>
            Self-service credits
          </Button>
        )}
        <Divider />
      </Box>
    );
  }

  if (smScreen || xsScreen) {
    return (
      <Box>
        <Typography sx={{ p: 1 }}>{props.header}</Typography>
        {show_credits_admin && <AdminToolbar />}
        <GridFilterPanel
          sx={{
            "& .MuiSvgIcon-root": { color: "#3c3c3c" },
            "& .MuiSvgIcon-root:hover": { color: "#63a528" },
          }}
        />
        {props.showSelfServ && (
          <Button
            variant="contained"
            sx={{ p: "2px" }}
            onClick={serviceHandler}
          >
            Self-service credits
          </Button>
        )}
        <Divider />
      </Box>
    );
  }
};

export default CustomToolbar;
