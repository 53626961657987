import React from "react";
import { useSelector } from "react-redux";
import GetAdmin from "./GetAdmin";

const AdminStatus = () => {
  const { userdataLoading, show_credits_admin } = useSelector((store) => store.user);

  if (userdataLoading === 'pending') {
    return;
  } else if (show_credits_admin === true) {
    return <GetAdmin />;
  } else {
    return <></>;
  }
}

export default AdminStatus;

