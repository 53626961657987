import { KeyboardArrowLeft, KeyboardArrowRight } from "@mui/icons-material";
import { Box, Button, MobileStepper } from "@mui/material";
import { useTheme } from "@mui/styles";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import SwipeableViews from "react-swipeable-views";
import ErrorBoundary from "../../lib/classes/ErrorBoundary";
import { uiActions } from "../../store/UI/uiSlice";
import gridBox from "./gridBox";
import ProductCard from "./ProductCard/ProductCard";
import ZeroCard from "./ZeroCard";

const CarouselViewAll = () => {
  const dispatch = useDispatch();
  const { creditListing } = useSelector((store) => store.credits);
  const { activeProduct } = useSelector((store) => store.ui);

  const theme = useTheme();

  const handleNext = () => {
    dispatch(uiActions.stepForward());
  };

  const handleBack = () => {
    dispatch(uiActions.stepBack());
  };

  const handleStepChange = (step) => {
    dispatch(uiActions.setActiveProduct(step));
  };

  const maxSteps = creditListing.length || 1;

  return (
    <ErrorBoundary>
      <SwipeableViews
        axis={theme.direction === "rtl" ? "x-reverse" : "x"}
        index={activeProduct}
        onChangeIndex={handleStepChange}
        enableMouseEvents
      >
        {creditListing.length !== 0 ? (
          creditListing.map((credit) => (
            <ProductCard key={credit.kind} credit={credit} />
          ))
        ) : (
          <Box component="span" sx={gridBox}>
            <ZeroCard />
          </Box>
        )}
      </SwipeableViews>
      <MobileStepper
        variant="text"
        steps={maxSteps}
        position="static"
        activeStep={activeProduct}
        nextButton={
          <Button
            size="small"
            onClick={handleNext}
            disabled={activeProduct === maxSteps - 1}
          >
            Next
            {theme.direction === "rtl" ? (
              <KeyboardArrowLeft />
            ) : (
              <KeyboardArrowRight />
            )}
          </Button>
        }
        backButton={
          <Button
            size="small"
            onClick={handleBack}
            disabled={activeProduct === 0}
          >
            {theme.direction === "rtl" ? (
              <KeyboardArrowRight />
            ) : (
              <KeyboardArrowLeft />
            )}
            Back
          </Button>
        }
      />
    </ErrorBoundary>
  );
};

export default CarouselViewAll;
