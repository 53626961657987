import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { AddModerator, Close } from "@mui/icons-material";
import {
  Button,
  Card,
  CardActions,
  Divider,
  FormControl,
  MenuItem,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { adminActions, assignCredits } from "../../store/admin/adminSlice";
import { enqueueSnackbar } from "../../store/notifications/actions";
import CopperheadFallback from "../custom/CopperheadFallback";

function AddCreditsSelf() {
  const { partner_id, partner_name } = useSelector((store) => store.user);
  const [cvalue, setValue] = React.useState("");
  const [disable, setDisable] = React.useState(true);
  const productRef = React.useRef("");
  const qtyRef = React.useRef("");
  const dispatch = useDispatch();
  const { adminLoading, adminCreateProducts, adminRenewProducts } = useSelector(
    (store) => store.admin
  );
  const [product, setProduct] = React.useState(+adminCreateProducts[0]?.kind || +adminRenewProducts[0].kind);

  const handleProduct = (event) => {
    setProduct(event.target.value);
  };

  const handleQuantity = (event) => {
    if (event.target.value >= 0) {
      setValue(event.target.value);
    }
    if (event.target.value > 0) {
      setDisable(false);
    }
  };

  const handleBlur = (event) => {
    if (!qtyRef.current.value) {
      setDisable(true);
    }

    if (qtyRef.current.value > 0) {
      setDisable(false);
    }
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    if (qtyRef.current.value <= 0) {
      dispatch(
        enqueueSnackbar({
          message: "You probably want to assign more than zero (0) credits.",
          options: {
            variant: "warning",
            autoHideDuration: 12000,
            resumeHideDuration: 800,
          },
        })
      );

      return;
    }

    const prodInput = productRef.current.value;
    const qtyInput = qtyRef.current.value;
    const mangle = `{ "partner_id": ${+partner_id}, "kind": ${+prodInput}, "qty": ${+qtyInput} }`;
    const realJson = JSON.parse(mangle);

    dispatch(assignCredits(realJson));
    dispatch(adminActions.setUpdateAdmin());
    dispatch(adminActions.handleSelfServ());
  };

  const handleClose = (event) => {
    event.preventDefault();

    dispatch(adminActions.handleSelfServ());
  };

  if (adminLoading) {
    return <CopperheadFallback />;
  }

  return (
    <Card
      sx={{ display: "inline-grid", p: 1, justifyContent: "space-between" }}
      id="dialog-self-service"
    >
      <Typography variant="h5">
        Partner: {partner_name}, #{partner_id}{" "}
      </Typography>
      <Divider />
      <FormControl>
        <TextField
          id="outlined-select-product"
          select
          label="Product"
          value={product}
          onChange={handleProduct}
          inputRef={productRef}
          helperText="Select a product package."
          sx={{ my: 2 }}
        >
          <Stack direction="column" sx={{ justifyContent: "center" }}>
            <Divider variant="middle"></Divider>
            <Typography variant="caption" sx={{ mx: "3px" }}>
              Create New Licence Products:
            </Typography>
            <Divider variant="middle"></Divider>
          </Stack>
          {adminCreateProducts.map((creator) => (
            <MenuItem key={creator.kind} value={creator.kind}>
              {creator.description}
            </MenuItem>
          ))}
          <Stack direction="column" sx={{ justifyContent: "center" }}>
            <Divider variant="middle"></Divider>
            <Typography variant="caption" sx={{ mx: "3px" }}>
              Renewal Products:
            </Typography>
            <Divider variant="middle"></Divider>
          </Stack>
          {adminRenewProducts.map((renewal) => (
            <MenuItem key={renewal.kind} value={renewal.kind}>
              {renewal.description}
            </MenuItem>
          ))}
          <Divider variant="middle"></Divider>
        </TextField>

        <TextField
          value={cvalue}
          type="number"
          inputProps={{ inputMode: "numeric", pattern: "[0-9]*" }}
          id="filled-credit-quant"
          label="Credits to add..."
          onChange={handleQuantity}
          onBlur={handleBlur}
          inputRef={qtyRef}
          helperText="Select the number of credits to add."
          sx={{ my: 2 }}
          variant="filled"
        />
        <Divider />
        <CardActions sx={{ justifyContent: "space-between" }}>
          <Button
            endIcon={<Close />}
            variant="contained"
            sx={{ p: 1 }}
            onClick={handleClose}
          >
            Cancel
          </Button>
          <Button
            disabled={disable}
            startIcon={<AddModerator />}
            variant="contained"
            sx={{ p: 1 }}
            onClick={handleSubmit}
          >
            Confirm
          </Button>
        </CardActions>
      </FormControl>
    </Card>
  );
}

export default AddCreditsSelf;
